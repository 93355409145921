import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import componentCreatorReducer from "./modules/component-creator/reducers";
import componentResponderReducer from "./modules/component-responder/reducers";
import profileSettingsReducer from "./modules/profile-settings/reducers";

const rootReducer = combineReducers({
  componentCreator: componentCreatorReducer,
  profileSettings: profileSettingsReducer,
  componentResponder: componentResponderReducer
});

const composeEnhancers = composeWithDevTools({ trace: false, traceLimit: 25 });

function newStore() {
  return createStore(
    rootReducer,
    undefined,
    composeEnhancers(applyMiddleware(thunk))
  );
}

let store = newStore();

export default function getStore() {
  return store;
}

export function getState() {
  return store.getState();
}