import React, { useEffect } from "react";
import "./assets/css/style.css";
import "./App.css";
import { Redirect, Router, useLocation } from "@reach/router";

import tawkTo from "tawkto-react";

import SuperAdminDashboard from "./components/view/SuperAdmin";
import AdminDashboard from "./components/AdminDashboard";
import NewAdminContainer from "./components/view/AdminPage";
import ProfileSettings from "./components/Settings/ProfileSettings";
import EmailSettings from "./components/Settings/EmailSettings";

const Login = React.lazy(() => import("./components/Login"));

const SignUp = React.lazy(() => import("./components/SignUp"));

const ForgetPassword = React.lazy(() => import("./components/ForgetPassword"));

const ResetPassword = React.lazy(() => import("./components/ResetPassword"));

const VerifyToken = React.lazy(() => import("./components/VerifyToken"));

const VerifyPassChange = React.lazy(() =>
  import("./components/VerifyPassChange")
);

const PasswordSent = React.lazy(() => import("./components/PasswordSent"));

const Responder = React.lazy(() => import("./components/Responder/index"));

// const MedicalSurvey = React.lazy(() =>
//   import("./components/Responder/medical")
// );

const OutreachSurvey = React.lazy(() =>
  import("./components/Responder/outreach")
);

const VideoWeb = React.lazy(() =>
  import("./components/AdminDashboard/InterviewPlace")
);

const ChatWeb = React.lazy(() => import("./components/AdminDashboard/Chat"));

const LandingPage = React.lazy(() => import("./components/LandingPage"));

const Settings = React.lazy(() => import("./components/Settings"));
const CongratulationScreen = React.lazy(() =>
  import("./components/CongratulationScreen")
);

const NewSuperAdmin = React.lazy(() =>
  import("./components/view/SuperAdmin/Dashboard")
);

const SuperAdminUsers = React.lazy(() =>
  import("./components/view/SuperAdmin/Users")
);

const SuperAdminOrganisations = React.lazy(() =>
  import("./components/view/SuperAdmin/Organisations")
);

const SuperAdminAgents = React.lazy(() =>
  import("./components/view/SuperAdmin/Agents")
);

const NewAdmin = React.lazy(() =>
  import("./components/view/AdminPage/Dashboard")
);

const NewAdminData = React.lazy(() =>
  import("./components/view/AdminPage/DataEntries")
);

const NewViewSurvey = React.lazy(() =>
  import("./components/view/AdminPage/ViewSurvey")
);

const NewSurvey = React.lazy(() =>
  import("./components/view/AdminPage/Surveys")
);

const NewAgent = React.lazy(() => import("./components/view/AdminPage/Agents"));

const NewMedicalOutreach = React.lazy(() =>
  import("./components/view/AdminPage/MedicalOutreach")
);

const NewMedicalSurvey = React.lazy(() =>
  import("./components/view/AdminPage/MedicalSurvey")
);

const NewInterview = React.lazy(() =>
  import("./components/view/AdminPage/Interview")
);

const NewViewResponse = React.lazy(() =>
  import("./components/view/AdminPage/ViewSurvey")
);

const NewDataEntriesSurvey = React.lazy(() =>
  import("./components/view/AdminPage/DataEntries/survey")
);

const Creator = React.lazy(() => import("./components/AdminDashboard/Creator"));

const NewDataEntriesOutreach = React.lazy(() =>
  import("./components/view/AdminPage/DataEntries/outreach")
);

const NewDataEntriesMedical = React.lazy(() =>
  import("./components/view/AdminPage/DataEntries/medical")
);

const NewResearch = React.lazy(() =>
  import("./components/view/AdminPage/Research")
);

const NewResearchSelect = React.lazy(() =>
  import("./components/view/AdminPage/Research/select")
);

const NewResearchSurveys = React.lazy(() =>
  import("./components/view/AdminPage/Research/survey")
);

// const NewResearchMedicalSurvey =React.lazy(()=>import( "./components/view/AdminPage/Research/medical"));

const NewResearchInterview = React.lazy(() =>
  import("./components/view/AdminPage/Research/interview")
);
const Soon = React.lazy(() =>
  import("./components/view/AdminPage/comingsoon/")
);

const OpenAI = React.lazy(()=> import("./components/view/AdminPage/OpenAI"))

export default () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes("/i/")) return;
    tawkTo("60f98abfd6e7610a49ac8294", "1fb7cbvu8");
  }, [pathname]);

  return (
    <div>
      <Router className="router">
        <LandingPage path="/" />
        <Login path="login" />
        <SignUp path="signup" />
        <ForgetPassword path="forgetpassword" />
        <ResetPassword path="resetpassword" />
        <VerifyToken path="verifytoken" />
        <VerifyPassChange path="verify" />
        {/* New Responder */}
        <Responder path="s/:surveyID" type={"surveys"} />
        <Responder path="m/:surveyID" type={"medical"} />
        <CongratulationScreen path="congrats" />

        {/* <MedicalSurvey path="m/:surveyID" /> */}

        {/* refactor */}
        <OutreachSurvey path="o/:surveyID" />

        <PasswordSent path="sent" />
        <VideoWeb path="i/:meetingId" />
        <ChatWeb path="c/:meetingId" />
        <NewAdminContainer path="org/dashboard">
          <NewAdmin path="/" />
          <OpenAI path="chat" />
          <NewAdminData path="dataentries" />
          <Settings path={"settings"}>
            <ProfileSettings path={"profile"} />
            <EmailSettings path={"emails"} />
            <Redirect to={"profile"} from={"/"} />
          </Settings>
          <NewViewSurvey path="view" />
          <NewSurvey path="surveys" />
          <NewAgent path="agents" />
          <NewMedicalOutreach path="medicaloutreach" />
          <NewMedicalSurvey path="medicalsurvey" />
          <NewInterview path="interviews" />
          <NewViewResponse path="dataentries/response/:type/:surveyID" />
          <NewDataEntriesOutreach path="dataentries/medicaloutreach" />
          <NewDataEntriesMedical path="dataentries/medicalsurvey" />
          <NewDataEntriesSurvey path="dataentries/surveys" />
          <NewResearch path="research" />
          <NewResearchSelect path="research/:researchId" />
          <NewResearchSurveys path="research/:researchId/surveys" />
          <NewResearchInterview path="research/:researchId/interviews" />
          <Soon path="soon" />
          <NotFound default />
        </NewAdminContainer>
        <AdminDashboard path="org/ex/">
          <Creator path="survey/view/survey/:id" type={"surveys"} />
          <Creator path="survey/view/medicalsurvey/:id" type={"medical"} />
          <Creator path="survey/view/medicaloutreach/:id" type={"outreach"} />
          <NotFound default />
        </AdminDashboard>
        <SuperAdminDashboard path="admin/dashboard">
          <NewSuperAdmin path="/" />
          <SuperAdminUsers path="/users" />
          <OpenAI path="chat" />
          <SuperAdminOrganisations path="/organisations" />
          <SuperAdminAgents path="/organisations/:organisationId/agents" />
        </SuperAdminDashboard>
        <NotFound default />
      </Router>
    </div>
  );
};

const NotFound = () => <h2>Not Found 404</h2>;
