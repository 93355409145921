import React from "react";
import css from "./index.module.css";
import { Switch } from "antd/es/";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileOnServer } from "../../../store/modules/profile-settings/actions";

export default function EmailSettings() {
  const dispatch = useDispatch();
  const profileSettings = useSelector((state) => state.profileSettings.data);

  // useEffect(() => {
  //   dispatch(fetchProfile());
  //   // eslint-disable-next-line
  // }, []);

  const handleChange = (checked) => {
    dispatch(updateProfileOnServer({ sendNewItemEmails: checked }));
  };

  return (
    <>
      <h4>Email Settings</h4>
      <hr />
      <div className={css.emailSettingsContainer}>
        <div className={css.emailText}>
          <h6>Receive Email Notification</h6>
          <Switch
            size={"medium"}
            checked={profileSettings.sendNewItemEmails}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
}
