export const FETCH_COMPONENT_IS_LOADING =
  "@mydataintel/component-creator/fetch-is-loading";
export const FETCH_COMPONENT_FAILED =
  "@mydataintel/component-creator/fetch-failed";
export const FETCH_COMPONENT_COMPLETED =
  "@mydataintel/component-creator/fetch-completed";
export const CREATE_QUESTION_IS_LOADING =
  "@mydataintel/component-creator/create-question-is-loading";
export const CREATE_QUESTION_FAILED =
  "@mydataintel/component-creator/create-question-failed";
export const CREATE_QUESTION_COMPLETED =
  "@mydataintel/component-creator/create-question-completed";
export const UPDATE_QUESTION = "@mydataintel/component-creator/update-question";
export const DELETE_QUESTION = "@mydataintel/component-creator/delete-question";
export const ADD_QUESTION = "@mydataintel/component-creator/add-question";
export const SET_ACTIVE_QUESTION =
  "@mydataintel/component-creator/set-active-question";
export const SAVING_DIRTY_WORK =
  "@mydataintel/component-creator/saving-dirty-work";
export const UPDATE_COMPONENT_DESCRIPTION =
  "@mydataintel/component-creator/update-component-description";
export const OPEN_CONFIGURATION_DRAWER =
  "@mydataintel/component-creator/open-configuration-drawer";
export const CLOSE_CONFIGURATION_DRAWER =
  "@mydataintel/component-creator/close-configuration-drawer";
export const UPDATE_PREREQUISITES =
  "@mydataintel/component-creator/update-prerequisites";
export const DIRTY_PREREQUISITES =
  "@mydataintel/component-creator/dirty-prerequisites";
export const DIRTY_THEME = "@mydataintel/component-creator/dirty-theme";
export const DIRTY_QUOTA = "@mydataintel/component-creator/dirty-quota";
export const PUBLISH_COMPONENT =
  "@mydataintel/component-creator/publish-component";
export const UPDATE_COMPONENT_NAME =
  "@mydataintel/component-creator/update-component-name";
export const INIT = "@mydataintel/component-creator/init";
export const UPDATE_COMPONENT_QUOTA =
  "@mydataintel/component-creator/update-component-quota";
export const UPDATE_COMPONENT_THEME =
  "@mydataintel/component-creator/update-component-theme";
export const UPDATE_QUESTION_IMG =
  "@mydataintel/component-creator/update-question-img";

export const defaultComponentCreatorState = {
  loading: false,
  createQuestionLoading: false,
  data: {},
  error: false,
  drawer: {
    open: false,
    dirtyPrerequisite: false,
    dirtyTheme: false,
    dirtyQuota: false,
  },
};
