import Notiflix from "notiflix";
import {Prerequisites, QuestionType} from "../types/enums";

const getFromStorage = (key) => {
  return localStorage.getItem(key)
    ? JSON.parse(localStorage.getItem(key))
    : false;
};

const getTokenFromStorage = () => {
  return getFromStorage("authentication-token");
};

const removeFromStorage = (key) => {
  return localStorage.getItem(key) ? localStorage.removeItem(key) : false;
};

const removeTokenFromStorage = () => {
  return removeFromStorage("authentication-token");
};

const saveToStorage = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value));

const baseURL = "https://api.mydataintel.com/api/v1/";


// Array of API discovery doc URLs for APIs used by the quickstart
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = "https://www.googleapis.com/auth/calendar";
const notify = {
  toast: {
    success: (msg) => Notiflix.Notify.Success(msg),
    warning: (msg) => Notiflix.Notify.Warning(msg),
    info: (msg) => Notiflix.Notify.Info(msg),
    failure: (msg) => Notiflix.Notify.Failure(msg),
  },
  dialog: {
    success: ({ title, body, text }) =>
      Notiflix.Report.Success(title, body, text),
    warning: ({ title, body, text }) =>
      Notiflix.Report.Warning(title, body, text),
    info: ({ title, body, text }) => Notiflix.Report.Info(title, body, text),
    failure: ({ title, body, text }) =>
      Notiflix.Report.Failure(title, body, text),
  },
  block: {
    pulse: (element, msg) => {
      Notiflix.Block.Pulse(element, msg);
    },
    standard: (element, msg) => {
      Notiflix.Block.Standard(element, msg);
    },
    circle: (element, msg) => {
      Notiflix.Block.Circle(element, msg);
    },
    arrows: (element, msg) => {
      Notiflix.Block.Arrows(element, msg);
    },
    dots: (element, msg) => {
      Notiflix.Block.Dots(element, msg);
    },
    remove: (element) => {
      Notiflix.Block.Remove(element);
    },
    init: () => {
      Notiflix.Block.Init();
    },
    merge: (customProperties) => {
      Notiflix.Block.Merge({ ...customProperties });
    },
  },
  confirm: {
    init: () => Notiflix.Confirm.Init(),
    merge: (customProperties) => {
      Notiflix.Confirm.Merge({ ...customProperties });
    },
    display: (
      title,
      body,
      acceptText,
      declineText,
      acceptCallback,
      declineCallback
    ) =>
      Notiflix.Confirm.Show(
        title,
        body,
        acceptText,
        declineText,
        acceptCallback,
        declineCallback
      ),
  },
};
// const notify = {
//     pulse: (className) => Notiflix.Block.Pulse(className),
//     success: (message, callback) => Notiflix.Notify.Success(message, callback),
//     error: (message, callback) => Notiflix.Notify.Failure(message, callback),
//     removePulse: (className) => Notiflix.Block.Remove(className)
// }
const delrm = (e, index, setCode, code) => {
  let newArr = [...code];
  if (e.keyCode === 8) {
    newArr.splice(index, 1);
    return setCode(newArr);
  }
  newArr[index] = e.target.value;
  return setCode(newArr);
};
const addLeadingZero = (num) => {
  if (num < 10) return "0" + num;
  return num;
};
const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const NoneEmpty = (arr) => {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] === "") return true;
  }
  return false;
};
const deepComparison = (x, notify) => {
  let value;
  // eslint-disable-next-line
  x.map((q) => {
    switch (q.questionType) {
      case QuestionType.TEXT:
        if (q.question === "") {
          notify.toast.failure(
            `Please provide a question as question field cannot be empty`
          );
          value = false;
        } else {
          value = true;
        }
        break;
      case QuestionType.RANGE:
        if (q.question === "") {
          notify.toast.failure(
            `Please provide a question as question field cannot be empty`
          );
          value = false;
        } else {
          value = true;
        }
        break;
      case QuestionType.MULTIPLE:
        if (q.question === "") {
          notify.toast.failure(`Please provide a question `);
          value = false;
        } else if (NoneEmpty(q.options)) {
          notify.toast.failure(`Please provide an option`);
          value = false;
        } else {
          value = true;
        }
        break;
      case QuestionType.SINGLE:
        if (q.question === "") {
          notify.toast.failure(`Please provide a question `);
          value = false;
        } else if (NoneEmpty(q.options)) {
          notify.toast.failure(`Please provide an option`);
          value = false;
        } else {
          value = true;
        }
        break;
      default:
        break;
    }
  });
  return value;
};

const filter = (q) => {
  return q.filter((d) => d.deleted === false);
};
const prepareSingle = (q, r, p) => {
  let responses = [];
  let responseData;
  let qA;
  // eslint-disable-next-line
  r.map((response) => {
    // eslint-disable-next-line
    q.map((x) => {
      qA = response.answers.find((ans) => ans.questionId === x._id);
      qA.question = capitalize(x.question);
    });
    responseData = response;
    responses.push(responseData);
  });
  return { responses };
};
const prepareDataOutreach = (r, p) => {
  const finalP = [...p, "practitioners", "medications", "conditions"];
  const sortedPrerequisites = [];
  const prerequisite = {
    age: {
      data: [],
      responsesId: [],
    },
    city: {
      series: [],
      label: [],
      responsesId: [],
    },
    gender: {
      series: [0, 0, 0],
      label: ["male", "female", "rathernotsay"],
      responsesId: [],
    },
    country: {
      series: [],
      label: [],
      responsesId: [],
    },
    allergies: {
      series: [0, 0],
      label: ["yes", "no"],
      responsesId: [],
    },
    physiotherapy: {
      series: [0, 0],
      label: ["yes", "no"],
      responsesId: [],
    },
    ect: {
      series: [0, 0],
      label: [true, false],
      responsesId: [],
    },
  };
  // eslint-disable-next-line
  r.map((response) => {
    if (response.age) {
      if (!prerequisite.age.responsesId.includes(response._id)) {
        if (prerequisite.age.data.length >= 1) {
          const ageExistsIndex = prerequisite.age.data.findIndex(
            (x) => x[0] === response.age
          );
          if (ageExistsIndex >= 0) {
            prerequisite.age.data[ageExistsIndex] = [
              response.age,
              ++prerequisite.age.data[ageExistsIndex][1],
            ];
          } else {
            prerequisite.age.data.push([response.age, 1]);
          }
        } else {
          prerequisite.age.data.push([response.age, 1]);
        }
        prerequisite.age.responsesId.push(response._id);
      }
    }
    if (response.ect) {
      if (!prerequisite.ect.responsesId.includes(response._id)) {
        const index = prerequisite.ect.label.indexOf(response.ect, 0);
        prerequisite.ect.series[index] = ++prerequisite.ect.series[index];
        prerequisite.ect.responsesId.push(response._id);
      }
    }
    if (response.allergies) {
      if (!prerequisite.allergies.responsesId.includes(response._id)) {
        const index = prerequisite.allergies.label.indexOf(
          response.allergies,
          0
        );
        prerequisite.allergies.series[index] = ++prerequisite.allergies.series[
          index
        ];
        prerequisite.allergies.responsesId.push(response._id);
      }
    }
    if (response.city) {
      if (!prerequisite.city.responsesId.includes(response._id)) {
        if (prerequisite.city.label.length >= 1) {
          const index = prerequisite.city.label.indexOf(response.city, 0);
          if (index >= 0) {
            prerequisite.city.series[index] = ++prerequisite.city.series[index];
          } else {
            prerequisite.city.label.push(response.city);
            prerequisite.city.series.push(1);
          }
        } else {
          prerequisite.city.label.push(response.city);
          prerequisite.city.series.push(1);
        }
        prerequisite.city.responsesId.push(response._id);
      }
    }
    if (response.physiotherapy) {
      if (!prerequisite.physiotherapy.responsesId.includes(response._id)) {
        const index = prerequisite.physiotherapy.label.indexOf(
          response.physiotherapy,
          0
        );
        prerequisite.physiotherapy.series[index] = ++prerequisite.physiotherapy
          .series[index];
        prerequisite.physiotherapy.responsesId.push(response._id);
      }
    }
    if (response.gender) {
      if (!prerequisite.gender.responsesId.includes(response._id)) {
        const index = prerequisite.gender.label.indexOf(response.gender, 0);
        prerequisite.gender.series[index] = ++prerequisite.gender.series[index];
        prerequisite.gender.responsesId.push(response._id);
      }
    }
    if (response.country) {
      if (!prerequisite.country.responsesId.includes(response._id)) {
        if (prerequisite.country.label.length >= 1) {
          const index = prerequisite.country.label.indexOf(response.country, 0);
          if (index >= 0) {
            prerequisite.country.series[index] = ++prerequisite.country.series[
              index
            ];
          } else {
            prerequisite.country.label.push(response.country);
            prerequisite.country.series.push(1);
          }
        } else {
          prerequisite.country.label.push(response.country);
          prerequisite.country.series.push(1);
        }
        prerequisite.country.responsesId.push(response._id);
      }
    }
  });
  // eslint-disable-next-line
  finalP.map((x) => {
    switch (x) {
      case Prerequisites.AGE:
        const sortAge = prerequisite.age.data.sort((a, b) => a[0] - b[0]);
        const ages = {
          prerequisite: capitalize(x),
          series: sortAge.map((x) => x[1]),
          label: sortAge.map((x) => x[0]),
        };
        sortedPrerequisites.push(ages);
        break;
      case Prerequisites.CITY:
        const { responsesId, ...det } = prerequisite.city;
        const cities = {
          prerequisite: capitalize(x),
          ...det,
        };
        sortedPrerequisites.push(cities);
        break;
      case Prerequisites.GENDER:
        const gender = {
          prerequisite: capitalize(x),
          series: prerequisite.gender.series,
          label: prerequisite.gender.label,
        };
        sortedPrerequisites.push(gender);
        break;
      case Prerequisites.COUNTRY:
        const country = {
          prerequisite: capitalize(x),
          series: prerequisite.country.series,
          label: prerequisite.country.label,
        };
        sortedPrerequisites.push(country);
        break;
      case Prerequisites.ECT:
        const ect = {
          prerequisite: capitalize(x),
          series: prerequisite.ect.series,
          label: prerequisite.ect.label,
        };
        sortedPrerequisites.push(ect);
        break;
      case Prerequisites.ALLERGIES:
        const allergies = {
          prerequisite: capitalize(x),
          series: prerequisite.allergies.series,
          label: prerequisite.allergies.label,
        };
        sortedPrerequisites.push(allergies);
        break;
      case Prerequisites.PHYSIOTHERAPY:
        const physiotherapy = {
          prerequisite: capitalize(x),
          series: prerequisite.physiotherapy.series,
          label: prerequisite.physiotherapy.label,
        };
        sortedPrerequisites.push(physiotherapy);
        break;
      default:
        break;
    }
  });
  return { sortedPrerequisites };
};
const prepareData = (q, r, p) => {
  const result = [];
  const sortedPrerequisites = [];
  const prerequisite = {
    age: {
      data: [],
      responsesId: [],
    },
    city: {
      series: [],
      label: [],
      responsesId: [],
    },
    gender: {
      series: [0, 0, 0],
      label: ["male", "female", "rathernotsay"],
      responsesId: [],
    },
    country: {
      series: [],
      label: [],
      responsesId: [],
    },
    bldpressure: {
      answers: [],
    },
    bldsugar: {
      answers: [],
    },
  };
  // eslint-disable-next-line
  q.map((x) => {
    let ques = {
      question: x.question,
      questionType: x.questionType,
      responseCount: 0,
    };
    const types = {
      range: {
        series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        label: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      },
      multiple: {
        series: Array.from(Array(x.options.length), (v) => 0),
        label: x.options,
      },
      single: {
        series: Array.from(Array(x.options.length), (v) => 0),
        label: x.options,
      },
      text: {
        answers: [],
      },
    };
    // eslint-disable-next-line
    r.map((response) => {
      const qA = response.answers.find((ans) => ans.questionId === x._id);
      if (response.age) {
        if (!prerequisite.age.responsesId.includes(response._id)) {
          if (prerequisite.age.data.length >= 1) {
            const ageExistsIndex = prerequisite.age.data.findIndex(
              (x) => x[0] === response.age
            );
            if (ageExistsIndex >= 0) {
              prerequisite.age.data[ageExistsIndex] = [
                response.age,
                ++prerequisite.age.data[ageExistsIndex][1],
              ];
            } else {
              prerequisite.age.data.push([response.age, 1]);
            }
          } else {
            prerequisite.age.data.push([response.age, 1]);
          }
          prerequisite.age.responsesId.push(response._id);
        }
      }
      if (response.bldpressure) {
        prerequisite.bldpressure.answers.push(response.bldpressure);
      }
      if (response.bldsugar) {
        prerequisite.bldsugar.answers.push(response.bldsugar);
      }
      if (response.city) {
        if (!prerequisite.city.responsesId.includes(response._id)) {
          if (prerequisite.city.label.length >= 1) {
            const index = prerequisite.city.label.indexOf(response.city, 0);
            if (index >= 0) {
              prerequisite.city.series[index] = ++prerequisite.city.series[
                index
              ];
            } else {
              prerequisite.city.label.push(response.city);
              prerequisite.city.series.push(1);
            }
          } else {
            prerequisite.city.label.push(response.city);
            prerequisite.city.series.push(1);
          }
          prerequisite.city.responsesId.push(response._id);
        }
      }
      if (response.gender) {
        if (!prerequisite.gender.responsesId.includes(response._id)) {
          const index = prerequisite.gender.label.indexOf(response.gender, 0);
          prerequisite.gender.series[index] = ++prerequisite.gender.series[
            index
          ];
          prerequisite.gender.responsesId.push(response._id);
        }
      }
      if (response.country) {
        if (!prerequisite.country.responsesId.includes(response._id)) {
          if (prerequisite.country.label.length >= 1) {
            const index = prerequisite.country.label.indexOf(
              response.country,
              0
            );
            if (index >= 0) {
              prerequisite.country.series[index] = ++prerequisite.country
                .series[index];
            } else {
              prerequisite.country.label.push(response.country);
              prerequisite.country.series.push(1);
            }
          } else {
            prerequisite.country.label.push(response.country);
            prerequisite.country.series.push(1);
          }
          prerequisite.country.responsesId.push(response._id);
        }
      }
      if (qA) {
        ques.responseCount = ques.responseCount + 1;
        switch (x.questionType) {
          case QuestionType.RANGE:
            types.range.series[Number(qA.replies[0])] =
              types.range.series[Number(qA.replies[0])] + 1;
            ques = { ...ques, ...types.range };
            break;

          case QuestionType.MULTIPLE:
            // eslint-disable-next-line
            qA.replies.map((reply) => {
              types.multiple.series[types.multiple.label.indexOf(reply, 0)] =
                types.multiple.series[types.multiple.label.indexOf(reply, 0)] +
                Number(1);
            });
            ques = { ...ques, ...types.multiple };
            break;
          case QuestionType.SINGLE:
            const reply = qA.replies[0];
            types.single.series[types.single.label.indexOf(reply, 0)] =
              types.single.series[types.single.label.indexOf(reply, 0)] +
              Number(1);
            ques = { ...ques, ...types.single };
            break;
          case QuestionType.TEXT:
            types.text.answers.push(qA.replies[0]);
            ques = { ...ques, ...types.text };
            break;

          default:
            break;
        }
      }
    });
    result.push(ques);
  });
  // eslint-disable-next-line
  p.map((x) => {
    switch (x) {
      case Prerequisites.AGE:
        const sortAge = prerequisite.age.data.sort((a, b) => a[0] - b[0]);
        const ages = {
          prerequisite: capitalize(x),
          series: sortAge.map((x) => x[1]),
          label: sortAge.map((x) => x[0]),
        };
        sortedPrerequisites.push(ages);
        break;
      case Prerequisites.CITY:
        const { responsesId, ...det } = prerequisite.city;
        const cities = {
          prerequisite: capitalize(x),
          ...det,
        };
        sortedPrerequisites.push(cities);
        break;
      case Prerequisites.GENDER:
        const gender = {
          prerequisite: capitalize(x),
          series: prerequisite.gender.series,
          label: prerequisite.gender.label,
        };
        sortedPrerequisites.push(gender);
        break;
      case Prerequisites.COUNTRY:
        const country = {
          prerequisite: capitalize(x),
          series: prerequisite.country.series,
          label: prerequisite.country.label,
        };
        sortedPrerequisites.push(country);
        break;
      case Prerequisites.BLDPRESSURE:
        const bldpressure = {
          prerequisite: capitalize(x),
          answers: prerequisite.bldpressure.answers,
        };
        sortedPrerequisites.push(bldpressure);
        break;
      case Prerequisites.BLDSUGAR:
        const bldsugar = {
          prerequisite: capitalize(x),
          answers: prerequisite.bldsugar.answers,
        };
        sortedPrerequisites.push(bldsugar);
        break;
      default:
        break;
    }
  });
  return { result, sortedPrerequisites };
};

const getGreeting = (hours) => {
  const greeting =
    hours < 12
      ? "Good Morning"
      : hours <= 18 && hours >= 12
      ? "Good Afternoon"
      : "it's Night";
  return greeting;
};

const debounce = (func, delay = 2000) => {
  let debounceTimer;
  const f = function(...args) {
    clearTimeout(debounceTimer)
    debounceTimer
      = setTimeout(() => func.apply(this, args), delay)
  };

  f.cancel = () => {
    clearTimeout(debounceTimer);
  }

  return f;
}

export {
  addLeadingZero,
  capitalize,
  deepComparison,
  getFromStorage,
  getTokenFromStorage,
  saveToStorage,
  prepareData,
  prepareDataOutreach,
  removeFromStorage,
  removeTokenFromStorage,
  prepareSingle,
  debounce,
  baseURL,
  notify,
  delrm,
  filter,
  SCOPES,
  getGreeting,
  DISCOVERY_DOCS,
};
