import React, { useContext, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import { Tooltip } from "antd";
import { DrawerMenuController } from "../../../controllers";
import thecsaen from "../../../assets/thecsaen.svg";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { notify } from "../../../helpers/";
import { containerContext } from "../../../providers";

const drawerWidth = 250;
const spaceBetweenIcons = 5;
const DRAWER_USER_TYPE = DrawerMenuController.USERTYPE.ORG;

const DrawerMenuItem = ({ menuController, name, ...props }) => {
  const menuItemObject = menuController.getMenuItemFromName(name);
  return menuItemObject.icon(props);
};

const UserProfileDrawerMenu = ({
  isOpen,
  handleDrawerClose,
  handleDrawerOpen,
  delegateMenuItemClicked,
  drawerUserType = DRAWER_USER_TYPE,
  ...props
}) => {
  const context = useContext(containerContext);
  const menuController = new DrawerMenuController(drawerUserType);
  const primaryColor = menuController.getColorForUserType();
  let menu;
  useEffect(() => {
    // const currIndex = context.state.selectedProfileMenuItemIndex;
    // const currentRoute = window.location.pathname;
  }, [context.state.selectedProfileMenuItemIndex]);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      width: "100%",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(8) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(8) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    background: {
      backgroundColor: primaryColor,
      border: 0,
    },
    icon: {
      // fontSize: 13,
      color: "white",
    },
    iconPaddingBottom: {
      paddingBottom: 20,
    },
    iconMarginBottom: {
      marginBottom: spaceBetweenIcons,
      paddingLeft: "22px",
    },
    hideHeader: {
      display: "none",
    },
    noMarginTop: {
      marginTop: 0,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(4),
    },
    textFont: {
      fontFamily: "product sans",
      paddingTop: 5,
    },
    highlightedItemText: {
      fontFamily: "product sans !important",
      color: " white",
    },
    lastIconItem: {
      fontSize: 13,
      color: "white",
      marginTop: spaceBetweenIcons * 3,
      paddingLeft: "22px",
    },
    lastIconMenuText: {
      paddingTop: 0,
    },
    selectedMenuItem: {
      backgroundColor: "#305CA3  !important",
      marginBottom: spaceBetweenIcons,
      paddingLeft: "22px",
    },
    sidebarIcons: {
      height: "80%",
      paddingTop: "40px",
      display: "flex",
      flexDirection: "column",
    },
  }));

  const classes = useStyles();

  const setSelectedIndex = (idx) => {
    context.setSelectedProfileMenuItemIndex(idx);
  };
  const confirmLogout = () => {
    return menuItemClick(menu);
  };
  const menuItemClick = (item) => {
    context.setSearch("");
    let itemIndex = item.idx;
    delegateMenuItemClicked(item);
    if (item.isExitMenuItem) {
      itemIndex = menuController.getHomeRouteIndexForUserType();
    }
    setSelectedIndex(itemIndex);
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isOpen,
        [classes.drawerClose]: !isOpen,
      })}
      classes={{
        paper: clsx(
          {
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          },
          classes.background
        ),
      }}
    >
      <div className={classes.toolbar}>
        {isOpen && (
          <div style={{ margin: "0 auto", color: "white" }}>
            <img
              src={thecsaen}
              alt="thecsaen logo"
              style={{
                height: "25px",
                marginRight: `${isOpen ? "10px" : "0"}`,
              }}
              className="thecsaen-logo"
            />
          </div>
        )}
        <IconButton
          style={{ outline: "unset" }}
          onClick={isOpen ? handleDrawerClose : handleDrawerOpen}
        >
          <MenuIcon className={classes.icon} />
        </IconButton>
      </div>

      <List className={classes.sidebarIcons}>
        {menuController
          .getMenuItemArrayForUserType()
          .map(({ name, route, displayName }, index) => {
            const isCurrentlyHighlightedMenuItem =
              index === context.state.selectedProfileMenuItemIndex;
            const isLastMenuItem = menuController.isLastMenuItemForUserType(
              index
            );
            const menuItemObj = {
              idx: index,
              title: displayName,
              routeName: route,
              isExitMenuItem: isLastMenuItem,
            };

            if (isCurrentlyHighlightedMenuItem && !isOpen) {
              return (
                <Tooltip placement="leftBottom" title={displayName} key={index}>
                  <ListItem
                    button
                    onClick={() => menuItemClick(menuItemObj)}
                    className={classes.selectedMenuItem}
                  >
                    <ListItemIcon>
                      <DrawerMenuItem
                        menuController={menuController}
                        name={name}
                        className={classes.icon}
                        color={menuController.getColorForUserType()}
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.highlightedItemText}
                      primary={
                        <div className={classes.highlightedItemText}>
                          {displayName}
                        </div>
                      }
                    />
                  </ListItem>
                </Tooltip>
              );
            }
            if (isCurrentlyHighlightedMenuItem && isOpen) {
              return (
                <ListItem
                  button
                  key={index}
                  onClick={() => menuItemClick(menuItemObj)}
                  className={classes.selectedMenuItem}
                >
                  <ListItemIcon>
                    <DrawerMenuItem
                      menuController={menuController}
                      name={name}
                      className={classes.icon}
                      color={menuController.getColorForUserType()}
                    />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.highlightedItemText}
                    primary={
                      <div className={classes.highlightedItemText}>
                        {displayName}
                      </div>
                    }
                  />
                </ListItem>
              );
            }

            if (!isLastMenuItem && !isOpen) {
              return (
                <Tooltip placement="leftBottom" title={displayName} key={index}>
                  <ListItem
                    onClick={() => menuItemClick(menuItemObj)}
                    button
                    key={name}
                    className={
                      (classes.iconPaddingBottom,
                      classes.icon,
                      classes.iconMarginBottom)
                    }
                  >
                    <ListItemIcon>
                      <DrawerMenuItem
                        menuController={menuController}
                        name={name}
                        className={classes.icon}
                        color={"white"}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <div style={{ color: "white" }}>{displayName}</div>
                      }
                    />
                  </ListItem>
                </Tooltip>
              );
            }
            if (!isLastMenuItem && isOpen) {
              return (
                <ListItem
                  onClick={() => menuItemClick(menuItemObj)}
                  button
                  key={name}
                  className={
                    (classes.iconPaddingBottom,
                    classes.icon,
                    classes.iconMarginBottom)
                  }
                >
                  <ListItemIcon>
                    <DrawerMenuItem
                      menuController={menuController}
                      name={name}
                      className={classes.icon}
                      color={"white"}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <div style={{ color: "white" }}>{displayName}</div>
                    }
                  />
                </ListItem>
              );
            }

            return (
              <ListItem
                onClick={() => {
                  menu = menuItemObj;
                  notify.confirm.display(
                    "Do you want to Log Out ?",
                    "Please either click on Yes or No",
                    "Yes",
                    "No",
                    confirmLogout,
                    console.log("staying")
                  );
                }}
                button
                key={name}
                className={classes.lastIconItem}
              >
                <ListItemIcon>
                  <DrawerMenuItem
                    menuController={menuController}
                    name={name}
                    className={classes.icon}
                    color={"white"}
                  />
                </ListItemIcon>
                <ListItemText
                  className={classes.lastIconMenuText}
                  primary={
                    <div className={classes.lastIconMenuText}>
                      {displayName}
                    </div>
                  }
                />
              </ListItem>
            );
          })}
      </List>
    </Drawer>
  );
};

export default UserProfileDrawerMenu;
