import React, { useRef } from 'react';
import css from './index.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button } from 'antd';
import {
  updateProfileOnServer,
  setDirtyName,
  updateProfileName,
  updatePicture,
} from '../../../store/modules/profile-settings/actions';
import Upload from '../../common/Upload';

export default function ProfileSettings() {
  const dispatch = useDispatch();
  const { picture, name } = useSelector((state) => state.profileSettings.data);
  const { dirty, loading } = useSelector((state) => state.profileSettings);

  const uploadedImage = useRef(null);
  const fileRef = useRef(null);

  const openFileDialog = (e) => {
    fileRef.current.click();
  };

  const handleCallback = (files) => {
    const [file] = files;
    const reader = new FileReader();
    const { current } = uploadedImage;
    current.file = file;
    reader.onload = (e) => {
      current.src = e.target.result;
    };
    reader.readAsDataURL(file);
    dispatch(updatePicture(file));
  };

  const handleChange = (e) => {
    dispatch(setDirtyName(true));
    dispatch(updateProfileName(e.target.value));
  };
  console.log(loading);
  if (loading) return <div>Loading !!!</div>;
  return (
    <>
      <h4>Profile Information</h4>
      <hr />
      <div className={css.photoContainer}>
        <img
          ref={uploadedImage}
          onClick={openFileDialog}
          src={picture || require('../../../assets/home-bg.png')}
          alt="profile_img"
          className={css.photo}
        />

        <Upload ref={fileRef} callback={handleCallback} mime="" />
      </div>
      <div className={css.profileBox}>
        <div>
          <div>Organisation Name</div>
          <Input onChange={handleChange} value={name} />
        </div>
      </div>
      <div className={css.buttonArea}>
        <Button
          className={css.button}
          disabled={!dirty}
          onClick={() => {
            dispatch(setDirtyName(false));
            dispatch(updateProfileOnServer({ name }));
          }}>
          Save
        </Button>
      </div>
    </>
  );
}
