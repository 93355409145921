const CONSTANTS = {
    COLORS: {
        PRIMARY: "#1E3E71",
        ORG: "#1E3E71",
        ADMIN: "#1E3E71",
        SECONDARY: "#FFFFFF",
        PRIMARY_LITE: "#305CA3",
    },
    FONTS: {
        PRIMARY: "product sans",
    }
};

export default CONSTANTS;