import React, { useState } from "react";
import { Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  updateComponentName,
  updateComponentNameOnServer,
} from "../../store/modules/component-creator/actions";

const mapState = (state) => ({
  title: state.componentCreator.data?.name,
});

const Text = () => {
  const [edit, setEdit] = useState(false);

  const dispatch = useDispatch();

  const component = useSelector(mapState);

  const handleTitle = (event) => {
    dispatch(updateComponentName(event.target.value));
  };

  const save = (e) => {
    e.preventDefault();
    dispatch(updateComponentNameOnServer(component.title));
    setEdit(false);
  };

  return (
    <div className="d-flex align-center">
      {edit ? (
        <input
          placeholder="Survey name"
          value={component?.title}
          onChange={handleTitle}
          className="input-name-survey"
          style={{ fontSize: "1rem" }}
          onBlur={(e) => save(e)}
        />
      ) : (
        <>
          <p className="thecsaen-create">
            {component.title?.substring(0, 30) || "Untitled Survey"}
          </p>
          {!edit && (
            <Tooltip placement="bottom" title="edit">
              <span
                className="icon-edit_icon pl-3 align-center cursor-pointer"
                onClick={() => setEdit(true)}
              />
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
};

export default Text;
