import { reducerMultiplexer } from "../../utils";
import {
  ADD_QUESTION,
  CLOSE_CONFIGURATION_DRAWER,
  defaultComponentCreatorState,
  DELETE_QUESTION,
  DIRTY_PREREQUISITES,
  DIRTY_QUOTA,
  DIRTY_THEME,
  FETCH_COMPONENT_COMPLETED,
  FETCH_COMPONENT_FAILED,
  FETCH_COMPONENT_IS_LOADING,
  INIT,
  OPEN_CONFIGURATION_DRAWER,
  PUBLISH_COMPONENT,
  SAVING_DIRTY_WORK,
  SET_ACTIVE_QUESTION,
  UPDATE_COMPONENT_DESCRIPTION,
  UPDATE_COMPONENT_NAME,
  UPDATE_PREREQUISITES,
  UPDATE_QUESTION,
  UPDATE_COMPONENT_QUOTA,
  UPDATE_COMPONENT_THEME,
} from "./types";

export default function componentCreatorReducer(
  state = defaultComponentCreatorState,
  action
) {
  return reducerMultiplexer({
    [FETCH_COMPONENT_FAILED]: (state, action) => {
      return {
        ...state,
        error: true,
        data: action.error,
      };
    },
    [FETCH_COMPONENT_IS_LOADING]: (state, action) => {
      return {
        ...state,
        loading: action.data,
        error: undefined,
      };
    },
    [FETCH_COMPONENT_COMPLETED]: (state, action) => {
      return {
        ...state,
        error: false,
        data: action.data,
      };
    },
    [INIT]: (state, action) => {
      return defaultComponentCreatorState;
    },
    [UPDATE_QUESTION]: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          questions: (() => {
            let questions = [...state.data.questions];
            let index = questions.findIndex(
              (obj) => obj._id === action.position
            );
            questions[index] = action.data;
            return [...questions];
          })(),
        },
      };
    },
    [DELETE_QUESTION]: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          questions: (() => {
            let questions = [...state.data.questions];
            questions.splice(action.position, 1);
            return [...questions];
          })(),
        },
      };
    },
    [ADD_QUESTION]: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          questions: (() => {
            let questions = [...state.data.questions];
            questions.splice(action.position, 0, {
              ...action.data,
              questionNo: state.data.questions.length + 1,
            });
            return [...questions];
          })(),
        },
      };
    },
    [SET_ACTIVE_QUESTION]: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          activeQuestion: action.data,
        },
      };
    },
    [SAVING_DIRTY_WORK]: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          savingWork: action.data,
        },
      };
    },
    [UPDATE_COMPONENT_DESCRIPTION]: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          description: action.data,
        },
      };
    },
    [UPDATE_COMPONENT_QUOTA]: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          quota: action.data,
        },
      };
    },
    [UPDATE_COMPONENT_THEME]: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          theme: action.data,
        },
      };
    },
    [OPEN_CONFIGURATION_DRAWER]: (state, action) => {
      return {
        ...state,
        drawer: {
          ...state.drawer,
          open: true,
        },
      };
    },
    [CLOSE_CONFIGURATION_DRAWER]: (state, action) => {
      return {
        ...state,
        drawer: {
          ...state.drawer,
          open: false,
        },
      };
    },
    [DIRTY_PREREQUISITES]: (state, action) => {
      return {
        ...state,
        drawer: {
          ...state.drawer,
          dirtyPrerequisite: action.data,
        },
      };
    },
    [DIRTY_THEME]: (state, action) => {
      return {
        ...state,
        drawer: {
          ...state.drawer,
          dirtyTheme: action.data,
        },
      };
    },
    [DIRTY_QUOTA]: (state, action) => {
      return {
        ...state,
        drawer: {
          ...state.drawer,
          dirtyQuota: action.data,
        },
      };
    },
    [UPDATE_PREREQUISITES]: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          prerequisites: action.data,
        },
      };
    },
    [PUBLISH_COMPONENT]: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          published: action.data,
        },
      };
    },
    [UPDATE_COMPONENT_NAME]: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          name: action.data,
        },
      };
    }
  })(state, action);
}
