import React from "react";
import { Button } from "react-bootstrap";
// import { debounce } from "../../../helpers";

const CreateButton = ({ text, handleClick }) => {
  return (
    <div className="d-flex justify-content-end my-3">
      <Button className="btn-home-blue" onClick={() => handleClick()}>{text}</Button>
    </div>
  );
};

export { CreateButton };
