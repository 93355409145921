export enum QuestionType {
    SINGLE = "single",
    MULTIPLE = "multiple",
    TEXT = "text",
    RANGE = "range",
    SYMPTOMS = "symptoms",
    LOCATION = "location",
    IMAGE = "image",
    UPLOAD = "upload"
}

export enum Prerequisites {
    NAME = "name",
    AGE = "age",
    COUNTRY = "country",
    GENDER = "gender",
    COUNTY = "county",
    CITY = "city",
    POSTALCODE = "postalcode",
    BLDPRESSURE = "bldpressure",
    BLDSUGAR = "bldsugar",
    UPLOAD = "upload",
    HEARTRATE = "heartrate",
    TEMP = "temp",
    ECT = "ect",
    PSYCHIATRIC = "psychiatric",
    PHYSIOTHERAPY = "physiotherapy",
    ALLERGIES = "allergies",
}