import { getTokenFromStorage, removeTokenFromStorage } from "../../../helpers";
import API from "../../../helpers/api";
import { navigate } from "@reach/router";

import {
  FETCH_PROFILE_IS_LOADING,
  FETCH_PROFILE_FAILED,
  FETCH_PROFILE_COMPLETED,
  UPDATE_PROFILE,
  INIT,
  DIRTY_NAME,
  UPDATE_PROFILE_NAME,
} from "./types";

export function updatePicture(file) {
  return async function (dispatch) {
    let formdata = new FormData();
    formdata.append("file", file);
    const token = getTokenFromStorage();

    try {
      API.put(`users/profile-picture`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer: ${token}`,
        },
      });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          removeTokenFromStorage();
          await navigate("/login");
        }
        dispatch(
          fetchProfileFailed({
            data: error.response.data,
          })
        );
      }
    }
  };
}
export function fetchProfile() {
  return async function (dispatch) {
    dispatch(init());
    dispatch(fetchProfileIsLoading(true));

    const token = getTokenFromStorage();

    try {
      const response = await API.get(`users`, {
        headers: { Authorization: `Bearer: ${token}` },
      });

      dispatch(fetchProfileCompleted(response.data));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          removeTokenFromStorage();
          await navigate("/login");
        }
        dispatch(
          fetchProfileFailed({
            data: error.response.data,
          })
        );
      }
    } finally {
      dispatch(fetchProfileIsLoading(false));
    }
  };
}

export function updateProfileOnServer(data) {
  return async function (dispatch) {
    // dispatch(updateProfile);
    const token = getTokenFromStorage();
    try {
      const response = await API.put(`users`, data, {
        headers: { Authorization: `Bearer: ${token}` },
      });
      dispatch(updateProfile(response.data));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          removeTokenFromStorage();
          await navigate("/login");
        }
        dispatch(
          fetchProfileFailed({
            data: error.response.data,
          })
        );
      }
    } finally {
      dispatch(fetchProfileIsLoading(false));
    }
  };
}

function init() {
  return {
    type: INIT,
  };
}
function fetchProfileIsLoading(active) {
  return {
    type: FETCH_PROFILE_IS_LOADING,
    data: active,
  };
}

function fetchProfileFailed(error) {
  return {
    type: FETCH_PROFILE_FAILED,
    error: error,
  };
}

function fetchProfileCompleted(data) {
  return {
    type: FETCH_PROFILE_COMPLETED,
    data: data,
  };
}

function updateProfile(data) {
  return {
    type: UPDATE_PROFILE,
    data: data,
  };
}

export function setDirtyName(yes) {
  return {
    type: DIRTY_NAME,
    data: yes,
  };
}

export function updateProfileName(name) {
  return {
    type: UPDATE_PROFILE_NAME,
    data: name,
  };
}
