import React, { useCallback, useContext, useEffect } from "react";
import Style from "style-it";
import { Input } from "antd";
import { containerContext } from "../../../../providers";
import DropdownHeader from "../../../DropdownHeader";
import { fetchProfile } from "../../../../store/modules/profile-settings/actions";
import { useDispatch, useSelector } from "react-redux";
import { MdChat } from 'react-icons/md';
import { FirebaseContext } from "../../../../providers/firebase";
const { Search } = Input;

export default function ProfileHeader({
  isOpen,
  isHome,
  title = "Default",
  colorTheme,
  drawerUserType,
  ...props
}) {
  // const authState = props.authstate.state;
  const context = useContext(containerContext);
  const firebase = useContext(FirebaseContext);
  const { email, name, picture } = useSelector(
    (state) => state.profileSettings.data
  );

  let orgPicture =
    picture ||
    `https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${name}`;

  const dispatch = useDispatch();
  const [, setState] = React.useState({
    scrollY: window.scrollY,
    isOpen: false,
  });
  useEffect(() => {
    dispatch(fetchProfile());
    //eslint-disable-next-line
  }, []);
  const getWindowScrollY = useCallback(
    () => setState((state) => ({ ...state, scrollY: window.scrollY })),
    []
  );
  // const isFirstPage = state.scrollY < 100;

  React.useEffect(() => {
    window.addEventListener("scroll", getWindowScrollY);
  }, [getWindowScrollY]);

  return Style.it(
    ` 
      .resizeSmaller{
        transition: width 0s ease-in;
      }
      .resizeBigger{
        transition: width 0s ease-in;
      }
      a{
        color: "black" ;
      }
      span{
        background-color: ${colorTheme};
        color: "#fff";
      }
      .item a{
        color: "black !important";
      }
      .profile{
        border-radius:100%
      }
      .title__section{
        width: ${!isOpen ? "20%" : "45%"};
        text-align:center;

      }
      `,
    <div>
      <div
        className={`navbar __header ${
          isOpen ? "resizeSmaller" : "resizeBigger"
        }`}
        {...props}
      >
        <div
          style={{ fontSize: 17, color: colorTheme }}
          className="title__section"
        >
          {title}
        </div>
        <div className="__items ">
          <div>
            <Search
              placeholder="search"
              value={context.state.searchTerm}
              onSearch={(value) => context.setSearch(value)}
              onChange={(e) => context.setSearch(e.target.value)}
              className="nav-search"
            />
          </div>
        { title.toLowerCase().includes('chat') &&   
         <MdChat size={20}  className="mx-3 cursor-pointer" color="#0D8ABC" 
         onClick={()=> firebase.setDrawerOpen(true)}/>}
          <div>
            <DropdownHeader
              img={orgPicture}
              name={name}
              email={email}
              isDash={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
