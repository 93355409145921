import React, { useContext, useEffect } from "react";
import thecsaen from "../../assets/thecsaen.svg";
import { Link, Match } from "@reach/router";
import { Row } from "react-bootstrap";
import { Button, Input } from "antd";
import { containerContext } from "../../providers";
import Text from "./text";
import DropdownHeader from "../DropdownHeader";
import { connect, useDispatch } from "react-redux";
import { openConfigurationDrawer } from "../../store/modules/component-creator/actions";
import { IconDropdown } from "../../assets/svgs";
import { fetchProfile } from "../../store/modules/profile-settings/actions";

function NavigationBar({ display, surveyID, type, location, saving, profile }) {
  const context = useContext(containerContext);
  let img =
    profile?.picture ||
    `https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${profile?.name}`;
  const dispatch = useDispatch();
  console.debug(profile);
  useEffect(() => {
    dispatch(fetchProfile());
    //eslint-disable-next-line
  }, []);
  const handleOpenDrawer = () => {
    dispatch(openConfigurationDrawer());
  };

  return (
    <nav className="nav-l">
      <div className="navbar-light d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-between flex-grow-1">
          <div className="d-flex">
            <div className="thecsaen-logo-div d-none d-lg-block">
              <Link to="/org/dashboard">
                <img
                  src={thecsaen}
                  alt="thecsaen logo"
                  className="thecsaen-logo"
                />
              </Link>
            </div>
            <>{display ? <Text surveyID={surveyID} type={type} /> : null}</>
          </div>
          <div className={" align-center d-none d-lg-block"}>
            <i className={"auto-save"}>
              {saving
                ? "your data is being saved..."
                : "your data will be auto-saved as you type"}
            </i>
          </div>
        </div>

        <div className="flex-grow-1">
          <Row className="nav-right-div">
            {display ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <IconDropdown
                  onClick={handleOpenDrawer}
                  className="d-block d-lg-none mr-2"
                />
                <Button
                  className={`mr-3 publish-btn d-none d-lg-block`}
                  size="middle"
                  block
                  onClick={handleOpenDrawer}
                >
                  More options
                </Button>
                <DropdownHeader img={img} name={profile?.name} />
              </div>
            ) : (
              <Match path={location}>
                {(props) =>
                  props.match.uri === "/org/dashboard/agents" ? (
                    <div>
                      <Button
                        className="btn-home-blue mr-2"
                        onClick={() => context.showModal()}
                      >
                        Add Agent
                      </Button>
                      <Input.Search
                        placeholder="search"
                        onSearch={(value) => console.log(value)}
                        className="nav-search"
                      />
                      <DropdownHeader img={img} name={profile?.name} />
                    </div>
                  ) : props.match.uri === "/org/dashboard/research" ? (
                    <div>
                      <Button
                        className="btn-home-blue mr-2"
                        onClick={() => context.showModal()}
                      >
                        Create Research
                      </Button>
                      <Input.Search
                        placeholder="search"
                        onSearch={(value) => console.log(value)}
                        className="nav-search"
                      />
                      <DropdownHeader img={img} name={profile?.name} />
                    </div>
                  ) : props.match.uri.includes("/interviews") ? (
                    <div>
                      <Button
                        className="btn-home-blue mr-2"
                        onClick={() => context.showModal()}
                      >
                        Create Interview
                      </Button>
                      <Input.Search
                        placeholder="search"
                        onSearch={(value) => console.log(value)}
                        className="nav-search"
                      />
                      <DropdownHeader img={img} name={profile?.name} />
                    </div>
                  ) : (
                    <div>
                      <Input.Search
                        placeholder="search "
                        onSearch={(value) => console.log(value)}
                        className="nav-search"
                      />
                      <DropdownHeader img={img} name={profile?.name} />
                    </div>
                  )
                }
              </Match>
            )}
          </Row>
        </div>
      </div>
    </nav>
  );
}

const mapState = (state, props) => ({
  ...props,
  profile: state.profileSettings.data,
  saving: state.componentCreator.data?.savingWork,
});

const ConnectedNavigationBar = connect(mapState)(NavigationBar);

export default ConnectedNavigationBar;
