export const FETCH_PROFILE_IS_LOADING =
  "@mydataintel/profile-settings/fetch-is-loading";
export const FETCH_PROFILE_FAILED =
  "@mydataintel/profile-settings/fetch-failed";
export const FETCH_PROFILE_COMPLETED =
  "@mydataintel/profile-settings/fetch-completed";
export const UPDATE_PROFILE = "@mydataintel/profile-settings/update-profile";
export const INIT = "@mydataintel/profile-settings/init";
export const DIRTY_NAME = "@mydataintel/profile-settings/dirty-name";
export const UPDATE_PROFILE_NAME =
  "@mydataintel/profile-settings/update-profile-name";

export const defaultProfileState = {
  loading: false,
  data: {},
  dirty: false,
  error: false,
};
