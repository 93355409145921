export const FETCH_COMPONENT_IS_LOADING =
  "@mydataintel/component-responder/fetch-is-loading";
export const FETCH_COMPONENT_FAILED =
  "@mydataintel/component-responder/fetch-failed";
export const FETCH_COMPONENT_COMPLETED =
  "@mydataintel/component-responder/fetch-completed";
export const CONFIGURE_PREREQUISITES =
  "@mydataintel/component-responder/configure-prerequisite";
export const CONFIGURE_ANSWERS =
  "@mydataintel/component-responder/configure-answers";
export const INIT = "@mydataintel/component-responder/init";
export const SET_GEOLOCATION_STATE =
  "@mydataintel/component-responder/set-geolocation-state";
export const SET_COORDINATES =
  "@mydataintel/component-responder/set-coordinates";
export const UPDATE_PREREQUISITES =
  "@mydataintel/component-responder/update-prerequisites";

export const UPDATE_ANSWER = "@mydataintel/component-responder/update-answer";

export const SUBMIT_RESPONSE =
  "@mydataintel/component-responder/submit-response";

export const UPDATE_STEP_INCREMENT = "@mydataintel/component-responder/update-step-increment";
export const UPDATE_STEP_DECREMENT = "@mydataintel/component-responder/update-step-decrement";

export const defaultComponentResponderState = {
  loading: false,
  data: {},
  response: {},
  error: false,
  geoLocationState: "",
  submitted: false,
  step: 1,
};
