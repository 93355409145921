import { reducerMultiplexer } from "../../utils";
import {
  FETCH_COMPONENT_IS_LOADING,
  FETCH_COMPONENT_FAILED,
  defaultComponentResponderState,
  FETCH_COMPONENT_COMPLETED,
  CONFIGURE_PREREQUISITES,
  CONFIGURE_ANSWERS,
  INIT,
  SET_GEOLOCATION_STATE,
  SET_COORDINATES,
  UPDATE_PREREQUISITES,
  UPDATE_ANSWER,
  UPDATE_STEP_INCREMENT,
  UPDATE_STEP_DECREMENT,
} from "./types";

export default function componentCreatorReducer(
  state = defaultComponentResponderState,
  action
) {
  return reducerMultiplexer({
    [FETCH_COMPONENT_FAILED]: (state, action) => {
      return {
        ...state,
        error: true,
        data: action.error,
      };
    },
    [FETCH_COMPONENT_IS_LOADING]: (state, action) => {
      return {
        ...state,
        loading: action.data,
        error: undefined,
      };
    },
    [FETCH_COMPONENT_COMPLETED]: (state, action) => {
      return {
        ...state,
        error: false,
        data: action.data,
      };
    },
    [INIT]: (state, action) => {
      return defaultComponentResponderState;
    },
    [CONFIGURE_PREREQUISITES]: (state, action) => {
      return {
        ...state,
        response: { ...state.response, ...action.data },
      };
    },
    [CONFIGURE_ANSWERS]: (state, action) => {
      return {
        ...state,
        response: { ...state.response, answers: action.data },
      };
    },
    [SET_GEOLOCATION_STATE]: (state, action) => {
      return {
        ...state,
        geoLocationState: action.data,
      };
    },
    [SET_COORDINATES]: (state, action) => {
      return {
        ...state,
        response: { ...state.response, coordinates: action.data },
      };
    },
    [UPDATE_PREREQUISITES]: (state, action) => {
      return {
        ...state,
        response: {
          ...state.response,
          [action.data.key]: action.data.value,
        },
      };
    },
    [UPDATE_ANSWER]: (state, action) => {
      return {
        ...state,
        response: {
          ...state.response,
          answers: (() => {
            let answers = [...state.response.answers];
            let index = answers.findIndex(
              (obj) => obj.question._id === action.data.id
            );
            answers[index].replies = [action.data.answer];
            return [...answers];
          })(),
        },
      };
    },
    [UPDATE_STEP_INCREMENT]: (state, action) => {
      return {
        ...state,
        step: action.data + 1,
      };
    },
    [UPDATE_STEP_DECREMENT]: (state, action) => {
      return {
        ...state,
        step: action.data,
      };
    },
  })(state, action);
}
