import React from "react";
import Spin from "antd/es/spin";

const LoadingScreen = ({ message }) => {
  return (
    <div className="overlay">
      <div>
        <Spin size="large" />
        {message && <div>message</div>}
      </div>
    </div>
  );
};

export { LoadingScreen };
