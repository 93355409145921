/* eslint-disable linebreak-style */
import React, {useState, useContext} from "react";

import ProfileHeader from "../../../../components/view/AdminPage/ProfileHeader";
import UserProfileDrawerMenu from "../../../custom/UserProfileDrawerMenu";
import { navigate } from "@reach/router";
// import * as _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { removeFromStorage } from "../../../../helpers/";
import { containerContext } from "../../../../providers";

const selectedItemTitleKey = "selectedItemTitleKey";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(4)
  },
  textFont: {
    // fontFamily: "'Josefin Sans', sans-serif !important",
    paddingTop: 5,
  },
}));

export default ({ children, bgColor, ...props }) => {
  const classes = useStyles();
  const context = useContext(containerContext);
  const currentRoutePath = window.location.pathname;

  const getHeaderTitleFromRoutePath = (route) => {
    const segments = route.toLowerCase().split('/');
    if (segments[3] === "settings") return "Settings";
    if (segments[3] === "chat") return "Chat";
    return "Home";
  };
          
  const [pageTitle, setPageTitle] = useState(() => getHeaderTitleFromRoutePath(currentRoutePath));
  const [open, setOpen] = useState(false);
          
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  console.log(pageTitle);
  const menuItemClicked = ({ routeName, title }) => {
    if (title === "Logout") {
      removeFromStorage("authentication-token");
      context.setSearch("");
      navigate(routeName);
    }
    sessionStorage.setItem(selectedItemTitleKey, title);
    setPageTitle(title);
    context.setSearch("");
    navigate(routeName);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <UserProfileDrawerMenu
        isOpen={open}
        drawerUserType={props.drawerUserType}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
        delegateMenuItemClicked={menuItemClicked}
        props={props}
      />
      <ProfileHeader
        drawerUserType={props.drawerUserType}
        isOpen={open}
        title={pageTitle}
        colorTheme={props.colorTheme}
      />
      <main className={classes.content} style={{ backgroundColor: bgColor }}>
        {/* <div className={classes.toolbar} /> */}
        {children}
        {/* <Footer /> */}
      </main>
    </div>
  );
};
