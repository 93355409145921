import * as React from "react";
import ProfileFrame from "../AdminPage/ProfileFrame";
import { DrawerMenuController } from "../../../controllers";
import Guard from "../../../wrappers/authorizationSuper";
import { BugReporter } from "../../custom";

export default Guard(({ children, ...props }) => {
  return (
    <>
      <ProfileFrame
        drawerUserType={DrawerMenuController.USERTYPE.SUPERADMIN}
        colorTheme={""}
        {...props}
      >
        {children}
        <BugReporter />
      </ProfileFrame>
    </>
  );
});
