import * as React from "react";
import * as _ from "lodash";
import CONSTANTS from "../CONSTANTS";
import {
    IconAgents,
    IconDashboard,
    IconLogout,
    IconSurveys,
    // IconMedSurvey,
    // IconMedOutreach,
    IconInterviews,
    IconDataEntries,
    IconResearch,
    IconUser,
    IconOpenAI
} from "../assets/svgs";

const getMenuItemArrayForORG = () => {
    return _.values(DrawerMenuController.ORG.menuItems);
};

const getMenuItemArrayForSUPERADMIN = () => {
    return _.values(DrawerMenuController.SUPERADMIN.menuItems);
};

const getMenuItemsCountForORG = () => {
    return getMenuItemArrayForORG().length;
};

const getMenuItemsCountForSUPERADMIN = () => {
    return getMenuItemArrayForSUPERADMIN().length;
};

class DrawerMenuController {
    constructor(userType) {
        this.userType = userType;
    }

    getMenuItemArrayForUserType = () => {
        if (this.userType === DrawerMenuController.USERTYPE.SUPERADMIN) {
            return getMenuItemArrayForSUPERADMIN();
        }

        return getMenuItemArrayForORG();
    };
    getColorForUserType = () => {
        if (this.userType === DrawerMenuController.USERTYPE.SUPERADMIN)
            return CONSTANTS.COLORS.ORG;

        return CONSTANTS.COLORS.ORG;
    };
    getMenuItemsCountForUserType = () => {
        if (this.userType === DrawerMenuController.USERTYPE.ORG) {
            return getMenuItemsCountForORG();
        }
        return getMenuItemsCountForSUPERADMIN();
    };

    isLastMenuItemForUserType = (currentMenuItemIndex) => {
        const menuItemCount = this.getMenuItemsCountForUserType(this.userType);
        return menuItemCount - 1 === currentMenuItemIndex;
    };

    getMenuItemFromRoute = (routeToFind) => {
        const donorMenuItemValuesArray = this.getMenuItemArrayForUserType();
        let finalIndex = -1;
        const foundMenuItemObj = donorMenuItemValuesArray.find(
            ({route}, index) => {
                const isMatch = routeToFind.toLowerCase() === route.toLowerCase();
                if (isMatch) finalIndex = index;
                return isMatch;
            }
        );

        return {
            ...foundMenuItemObj,
            index: finalIndex,
        };
    };

    getMenuItemFromName = (nameToFind) => {
        const donorMenuItemValuesArray = this.getMenuItemArrayForUserType();
        let finalIndex = -1;
        const foundMenuItemObj = donorMenuItemValuesArray.find(
            ({name}, index) => {
                const isMatch = nameToFind.toLowerCase() === name.toLowerCase();
                if (isMatch) finalIndex = index;
                return isMatch;
            }
        );

        return {
            ...foundMenuItemObj,
            index: finalIndex,
        };
    };

    getIndexForRoute = (routeToFind) => {
        const foundMenuItem = this.getIndexForRoute(routeToFind);
        return foundMenuItem.index;
    };

    getIndexForName = (nameToFind) => {
        const foundMenuItem = this.getMenuItemFromName(nameToFind);
        return foundMenuItem.index;
    };

    getHomeRouteIndexForUserType = () => {
        let homeItem = this.getIndexForName("Home");
        if (homeItem < 0) homeItem = this.getIndexForName("Dashboard");

        return homeItem;
    };
}

DrawerMenuController.USERTYPE = {
    ORG: 1,
    SUPERADMIN: 2,
};

DrawerMenuController.ORG = {
    menuItems: {
        home: {
            name: "Dashboard",
            route: "/org/dashboard",
            displayName: "Dashboard",
            shouldDisplayTitle: true,
            icon: (props) => {
                return <IconDashboard {...props} />;
            },
        },
        dataEntries: {
            name: "Data Entries",
            route: "/org/dashboard/dataentries",
            displayName: "Data Entries",
            shouldDisplayTitle: true,
            icon: (props) => {
                return <IconDataEntries/>;
            },
        },
        agents: {
            name: "Agents",
            route: "/org/dashboard/agents",
            displayName: "Agents",
            shouldDisplayTitle: true,
            icon: (props) => {
                return <IconAgents/>;
            },
        },
        surveys: {
            name: "Surveys",
            route: "/org/dashboard/surveys",
            displayName: "Surveys",
            shouldDisplayTitle: true,
            icon: (props) => {
                return <IconSurveys/>;
            },
        },
        interviews: {
            name: "Interviews",
            route: "/org/dashboard/interviews",
            displayName: "Interviews",
            shouldDisplayTitle: true,
            icon: (props) => {
                return <IconInterviews/>;
            },
        },
        research: {
            name: "Research",
            route: "/org/dashboard/research",
            displayName: "Research",
            shouldDisplayTitle: true,
            icon: (props) => {
                return <IconResearch/>;
            },
        },
        chat: {
            name: "Chat AI",
            route: "/org/dashboard/chat",
            displayName: "Chat AI",
            shouldDisplayTitle: true,
            icon: (props) => {
                return <IconOpenAI width="17"/>;
            },
        },
        // medicalSurvey: {
        //     name: "Medical Survey",
        //     route: "/org/dashboard/medicalsurvey",
        //     displayName: "Medical Survey",
        //     shouldDisplayTitle: true,
        //     icon: (props) => {
        //         return <IconMedSurvey/>;
        //     },
        // },
        // medicalOutreach: {
        //     name: "Medical Outreach",
        //     route: "/org/dashboard/medicaloutreach",
        //     displayName: "Medical Outreach",
        //     shouldDisplayTitle: true,
        //     icon: (props) => {
        //         return <IconMedOutreach/>;
        //     },
        // },

        // BusinessAnalysis: {
        //     name: " Business Analysis",
        //     route: "/org/dashboard/soon",
        //     displayName: "Business Analysis",
        //     shouldDisplayTitle: true,
        //     icon: (props) => {
        //         return <IconMedSurvey/>;
        //     },
        // },
        // AgriculturalSurvey: {
        //     name: "Agricultural Survey",
        //     route: "/org/dashboard/soon",
        //     displayName: "Student Research",
        //     shouldDisplayTitle: true,
        //     icon: (props) => {
        //         return <IconMedSurvey/>;
        //     },
        // },

        logout: {
            name: "Logout",
            shouldDisplayTitle: false,
            route: "/",
            displayName: "Logout",
            icon: (props) => {
                return <IconLogout {...props} />;
            },
        },
    },
};

DrawerMenuController.SUPERADMIN = {
    menuItems: {
        home: {
            name: "Dashboard",
            route: "/admin/dashboard",
            displayName: "Dashboard",
            shouldDisplayTitle: true,
            icon: (props) => {
                return <IconDashboard {...props} />;
            },
        },
        users: {
            name: "Users",
            route: "/admin/dashboard/users",
            displayName: "Users",
            shouldDisplayTitle: true,
            icon: (props) => {
                return <IconAgents {...props} />;
            },
        },
        organisations: {
            name: "Organisations",
            route: "/admin/dashboard/organisations",
            displayName: "Organisations",
            shouldDisplayTitle: true,
            icon: (props) => {
                return <IconUser {...props} />;
            },
        },
        logout: {
            name: "Logout",
            shouldDisplayTitle: false,
            route: "/",
            displayName: "Logout",
            icon: (props) => {
                return <IconLogout {...props} />;
            },
        },
        chat: {
            name: "Chat AI",
            route: "/admin/dashboard/chat",
            displayName: "Chat AI",
            shouldDisplayTitle: true,
            icon: (props) => {
                return <IconOpenAI width="17"/>;
            },
        },
    },
};

export default DrawerMenuController;
