import React, { useState } from "react";
import API from "../../../helpers/api";
import Style from "style-it";
// import { ReactComponent as Bug } from "../../../assets/bug-report.svg";
import { Row, Col, Modal } from "react-bootstrap";
import { Button } from "antd";
import { notify } from "../../../helpers";

const BugReporter = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [subject, setSubject] = useState("");
  const [loading, setLoading] = useState(false);

  const close = () => setModalOpen(false);
  const handleReport = (e) => {
    e.preventDefault();
    setLoading(true);
    if (email === " " && description === "" && subject === "") {
      setLoading(false);
      return notify.toast.failure(
        "Please write a descriptive bug message ,  valid email and subject"
      );
    }
    let body = "FROM ORGANISATION WEB APP " + description;
    API.post(`issues`, {
      subject,
      body,
      email,
    })
      .then((res) => {
        close();
        setLoading(false);
        notify.toast.success("Bug report submitted successfully");
        setEmail("");
        setDescription("");
        setSubject("");
      })
      .catch((err) => {
        if (err.response) {
          setLoading(false);
          return notify.toast.failure(err.response.data.message);
        } else {
          setLoading(false);
          return notify.toast.failure(err);
        }
      });
  };

  return Style.it(
    `
        .bug{
            position: fixed;
            bottom: 80px;
            padding:1em;
            right: 25px;
           border-radius:50%;
            z-index: 1000000;
            cursor:pointer;
            background: #1E3E71;
        }
        svg{
          width:auto;
          fill:#fff;
          height: 25px;  
        }
       
        `,
    <div>
      {/*<div className="bug" onClick={() => setModalOpen(true)}>*/}
      {/*  <Bug />*/}
      {/*</div>*/}
      <Modal size={"md"} centered={true} show={modalOpen} onHide={close}>
        <Modal.Body className="modal-style">
          <Row className="mb-1">
            <Col lg={12}>
              <h5 className="add-person py-2 pl-2">Report Bug</h5>
            </Col>
          </Row>
          <form onSubmit={handleReport}>
            <Row className="mb-3">
              <Col>
                <input
                  type="text"
                  placeholder="Subject"
                  className="ash"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <input
                  type="email"
                  placeholder="Email"
                  className="ash"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <textarea
                  className="ash"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                ></textarea>
              </Col>
            </Row>
            <Row className="text-right">
              <Col>
                <Button
                  className="add-btn"
                  htmlType="submit"
                  size="middle"
                  loading={loading}
                >
                  Report
                </Button>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export { BugReporter };
