import { reducerMultiplexer } from "../../utils";
import {
  FETCH_PROFILE_IS_LOADING,
  FETCH_PROFILE_FAILED,
  FETCH_PROFILE_COMPLETED,
  UPDATE_PROFILE,
  defaultProfileState,
  INIT,
  UPDATE_PROFILE_NAME,
  DIRTY_NAME,
} from "./types";

export default function profileSettingsReducer(
  state = defaultProfileState,
  action
) {
  return reducerMultiplexer({
    [FETCH_PROFILE_FAILED]: (state, action) => {
      return {
        ...state,
        error: true,
        loading: false,
        data: action.error,
      };
    },
    [INIT]: (state, action) => {
      return defaultProfileState;
    },
    [FETCH_PROFILE_IS_LOADING]: (state, action) => {
      return {
        ...state,
        loading: action.data,
        error: undefined,
      };
    },
    [FETCH_PROFILE_COMPLETED]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.data,
      };
    },
    [UPDATE_PROFILE]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.data,
      };
    },
    [UPDATE_PROFILE_NAME]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: undefined,
        data: { ...state.data, name: action.data },
      };
    },
    [DIRTY_NAME]: (state, action) => {
      return {
        ...state,
        dirty: action.data,
      };
    },
  })(state, action);
}
