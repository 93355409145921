import React from "react";
import Navbar from "../NavigationBar";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../SideNavigation";
import { Match, Location } from "@reach/router";
import Guard from "../../wrappers/authorization";
import { BugReporter } from "../custom/";

const AdminDashboard = ({ children }) => {
  return (
    <Location>
      {({ location }) => (
        <Match path="survey/*/:surveyID">
          {(props) =>
            props.match ? (
              <div className="bg-ash">
                <Navbar
                  display={true}
                  surveyID={props.match["*"].split("/")[2]}
                  type={props.match["*"].split("/")[1]}
                />
                <Container
                  style={{ paddingTop: " 7rem", paddingBottom: "2rem" }}
                  fluid
                >
                  <Row>
                    <Col lg={12} md={12}>
                      <div>{children}</div>
                    </Col>
                  </Row>
                  <BugReporter />
                </Container>
              </div>
            ) : (
              <div className="bg-ash">
                <Navbar location={location.pathname} />
                <Container
                  style={{ paddingTop: " 7rem", paddingBottom: "2rem" }}
                  fluid
                >
                  <div className="content-container">
                    <div lg={2} className="sidenav">
                      <Sidebar />
                    </div>
                    <div lg={10} md={9}>
                      {children}
                    </div>
                  </div>
                  <BugReporter />
                </Container>
              </div>
            )
          }
        </Match>
      )}
    </Location>
  );
};

export default Guard(AdminDashboard);
