import { getTokenFromStorage, removeTokenFromStorage } from "../../../helpers";
import API from "../../../helpers/api";
import { navigate } from "@reach/router";
import { getState } from "../../";

import {
  ADD_QUESTION,
  UPDATE_COMPONENT_QUOTA,
  CLOSE_CONFIGURATION_DRAWER,
  CREATE_QUESTION_FAILED,
  CREATE_QUESTION_IS_LOADING,
  DIRTY_PREREQUISITES,
  DIRTY_THEME,
  DIRTY_QUOTA,
  FETCH_COMPONENT_COMPLETED,
  FETCH_COMPONENT_FAILED,
  FETCH_COMPONENT_IS_LOADING,
  INIT,
  OPEN_CONFIGURATION_DRAWER,
  PUBLISH_COMPONENT,
  SAVING_DIRTY_WORK,
  SET_ACTIVE_QUESTION,
  UPDATE_COMPONENT_DESCRIPTION,
  UPDATE_COMPONENT_NAME,
  UPDATE_PREREQUISITES,
  UPDATE_QUESTION,
  UPDATE_COMPONENT_THEME,
} from "./types";

export function fetchComponent(id, type) {
  return async function (dispatch) {
    dispatch(init());
    dispatch(fetchComponentIsLoading(true));

    const token = getTokenFromStorage();

    try {
      const response = await API.get(`${type}/${id}`, {
        headers: { Authorization: `Bearer: ${token}` },
      });

      const component = {
        ...response.data,
        type,
        _id: id,
      };

      if (type !== "outreach") {
        component.questions = response.data.questions.filter(
          (question) => question.deleted === false
        );
      }

      dispatch(fetchComponentCompleted(component));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          removeTokenFromStorage();
          await navigate("/login");
        }
        dispatch(
          fetchComponentFailed({
            data: error.response.data,
          })
        );
      }
    } finally {
      dispatch(fetchComponentIsLoading(false));
    }
  };
}

export function createQuestion(questionType, index) {
  return async function (dispatch) {
    dispatch(createQuestionIsLoading(true));
    const component = getState().componentCreator.data;

    const { _id: id, type } = component;
    const token = getTokenFromStorage();
    try {
      const response = await API.post(
        `${type}/${id}/questions?questionType=${questionType}`,
        {},
        {
          headers: { Authorization: `Bearer: ${token}` },
        }
      );
      const question = {
        ...response.data.question,
      };
      dispatch(addQuestion(question, index));
      dispatch(setActiveQuestion(index+1));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          removeTokenFromStorage();
          await navigate("/login");
        }
        dispatch(
          createQuestionFailed({
            data: error.response.data,
          })
        );
      }
    } finally {
      dispatch(createQuestionIsLoading(false));
    }
  };
}

export function deleteQuestionFromServer(questionId) {
  return async function (_) {
    if (!questionId) return;

    const token = getTokenFromStorage();

    const component = getState().componentCreator.data;

    const { _id: id, type } = component;

    try {
      await API.delete(`${type}/${id}/questions/${questionId}`, {
        headers: { Authorization: `Bearer: ${token}` },
      });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          removeTokenFromStorage();
          await navigate("/login");
        }
      }
    }
  };
}

const routeMap = {
  surveys: "surveys",
  medical: "medicalsurvey",
  outreach: "medicaloutreach",
};

export function deleteComponent() {
  return async function (_) {
    const token = getTokenFromStorage();

    const component = getState().componentCreator.data;

    const { _id: id, type } = component;

    try {
      await API.delete(`${type}/${id}`, {
        headers: { Authorization: `Bearer: ${token}` },
      });

      return navigate(`/org/dashboard/${routeMap[type]}`);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          removeTokenFromStorage();
          await navigate("/login");
        }
      }
    }
  };
}

export function saveQuestionToServer() {
  return async function (dispatch) {
    // if (!question.question) return;
    // if (!question.questionType) return;
    const token = getTokenFromStorage();
    const component = getState().componentCreator.data;
    let { _id: id, type, questions } = component;

    // dispatch(savingDirtyWork(true));

    try {
      questions = questions.map((question, index) => ({
        ...question,
        questionNo: index + 1,
      }));
      // if (question._id) {

      const response = await API.put(`${type}/${id}/questions`, questions, {
        headers: { Authorization: `Bearer: ${token}` },
      });
      //   return;
      // }

      // const response = await API.post(`${type}/${id}/questions`, question, {
      //   headers: { Authorization: `Bearer: ${token}` },
      // });
      console.log(response);
      // dispatch(updateQuestion(response.data, response.data.questionNo - 1));
    } catch (error) {
      console.error(error);
      if (error.response) {
        if (error.response.status === 401) {
          removeTokenFromStorage();
          await navigate("/login");
        }
      }
    } finally {
      dispatch(savingDirtyWork(false));
    }
  };
}
export function updateComponentQuotaOnServer(quota) {
  return async function (dispatch) {
    const token = getTokenFromStorage();

    const component = getState().componentCreator.data;

    const { _id: id, type } = component;
    try {
      await API.put(
        `${type}/${id}`,
        { quota: Number(quota) },
        {
          headers: { Authorization: `Bearer: ${token}` },
        }
      );
      dispatch(setDirtyQuota(false));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          removeTokenFromStorage();
          await navigate("/login");
        }
      }
    }
  };
}

export function updateComponentDescriptionOnServer(description) {
  return async function (_) {
    const token = getTokenFromStorage();

    const component = getState().componentCreator.data;

    const { _id: id, type } = component;

    try {
      await API.put(
        `${type}/${id}`,
        { description },
        {
          headers: { Authorization: `Bearer: ${token}` },
        }
      );
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          removeTokenFromStorage();
          await navigate("/login");
        }
      }
    }
  };
}

export function updateComponentNameOnServer(name) {
  return async function (_) {
    const token = getTokenFromStorage();

    const component = getState().componentCreator.data;

    const { _id: id, type } = component;

    try {
      await API.put(
        `${type}/${id}`,
        { name },
        {
          headers: { Authorization: `Bearer: ${token}` },
        }
      );
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          removeTokenFromStorage();
          await navigate("/login");
        }
      }
    }
  };
}
export function updateComponentThemeOnServer(theme) {
  return async function (dispatch) {
    const token = getTokenFromStorage();

    const component = getState().componentCreator.data;

    const { _id: id, type } = component;

    try {
      await API.put(
        `${type}/${id}`,
        { theme },
        {
          headers: { Authorization: `Bearer: ${token}` },
        }
      );
      dispatch(setDirtyTheme(false));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          removeTokenFromStorage();
          await navigate("/login");
        }
      }
    }
  };
}

export function savePrerequisitesToServer(prerequisites) {
  return async function (dispatch) {
    const token = getTokenFromStorage();

    const component = getState().componentCreator.data;

    const { _id: id, type } = component;
    console.log(id);
    try {
      await API.put(
        `${type}/${id}`,
        { prerequisites },
        {
          headers: { Authorization: `Bearer: ${token}` },
        }
      );
      dispatch(setDirtyPrerequisite(false));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          removeTokenFromStorage();
          await navigate("/login");
        }
      }
    }
  };
}

export function publishComponentToServer(yes) {
  return async function (dispatch) {
    const token = getTokenFromStorage();

    const component = getState().componentCreator.data;

    const { _id: id, type } = component;

    try {
      await API.put(
        `${type}/${id}`,
        { published: yes },
        {
          headers: { Authorization: `Bearer: ${token}` },
        }
      );
      dispatch(publishComponent(yes));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          removeTokenFromStorage();
          await navigate("/login");
        }
      }
    }
  };
}

export function updatePrerequisites(prerequisites) {
  return {
    type: UPDATE_PREREQUISITES,
    data: prerequisites,
  };
}

export function updateQuestion(question, i) {
  return {
    type: UPDATE_QUESTION,
    data: { ...question },
    position: i,
  };
}

export function updateComponentDescription(description) {
  return {
    type: UPDATE_COMPONENT_DESCRIPTION,
    data: description,
  };
}

export function savingDirtyWork(now) {
  return {
    type: SAVING_DIRTY_WORK,
    data: now,
  };
}

export function addQuestion(value, index) {
  return {
    type: ADD_QUESTION,
    data: value,
    position: index + 1,
  };
}

export function setActiveQuestion(index) {
  return {
    type: SET_ACTIVE_QUESTION,
    data: index,
  };
}

function createQuestionIsLoading(active) {
  return {
    type: CREATE_QUESTION_IS_LOADING,
    data: active,
  };
}

function createQuestionFailed(error) {
  return {
    type: CREATE_QUESTION_FAILED,
    error: error,
  };
}

function fetchComponentIsLoading(active) {
  return {
    type: FETCH_COMPONENT_IS_LOADING,
    data: active,
  };
}

function fetchComponentFailed(error) {
  return {
    type: FETCH_COMPONENT_FAILED,
    error: error,
  };
}

function fetchComponentCompleted(data) {
  return {
    type: FETCH_COMPONENT_COMPLETED,
    data: data,
  };
}

function init() {
  return {
    type: INIT,
  };
}

export function openConfigurationDrawer() {
  return {
    type: OPEN_CONFIGURATION_DRAWER,
  };
}

export function closeConfigurationDrawer() {
  return {
    type: CLOSE_CONFIGURATION_DRAWER,
  };
}

export function setDirtyPrerequisite(yes) {
  return {
    type: DIRTY_PREREQUISITES,
    data: yes,
  };
}

export function setDirtyTheme(yes) {
  return {
    type: DIRTY_THEME,
    data: yes,
  };
}
export function setDirtyQuota(yes) {
  return {
    type: DIRTY_QUOTA,
    data: yes,
  };
}

export function publishComponent(yes) {
  return {
    type: PUBLISH_COMPONENT,
    data: yes,
  };
}

export function updateComponentName(name) {
  return {
    type: UPDATE_COMPONENT_NAME,
    data: name,
  };
}

export function updateComponentQuota(quota) {
  return {
    type: UPDATE_COMPONENT_QUOTA,
    data: quota,
  };
}

export function updateComponentTheme(theme) {
  return {
    type: UPDATE_COMPONENT_THEME,
    data: theme,
  };
}

export function uploadMedia(media, id) {
  return async function (dispatch) {
    let formdata = new FormData();
    formdata.append("file", media);

    try {
      const {
        data: { url },
      } = await API.post(`upload`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const question = getState().componentCreator?.data?.questions.find(({_id}) => _id === id)
      dispatch(updateQuestion({picture: url, ...question}, id));
    } catch (error) {
      if (error.response) {
        dispatch(
          fetchComponentFailed({
            data: error.response.data,
          })
        );
      }
    }
  };
}
