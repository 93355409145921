import React, { useState, useEffect } from "react";
import { Link, navigate } from "@reach/router";
import { Card } from "antd";
import decoder from "jwt-decode";
import { getFromStorage, removeFromStorage, getGreeting } from "../../helpers";

const Sidebar = () => {
  const [user, setUser] = useState({ name: "" });
  useEffect(() => {
    const token = getFromStorage("authentication-token");
    const userd = decoder(token);
    setUser(userd);
  }, []);
  const logout = () => {
    removeFromStorage("authentication-token");
    navigate("/");
  };

  return (
    <div>
      <div lg={12}>
        <Card className="userinfo mb-4">
          <div lg={12}>
            <div>
              <div style={{ display: "flex" }}>
                <h3 className="username">{user.name},</h3>
                <span
                  className="icon-afternoon_icon"
                  style={{
                    alignSelf: " flex-end",
                    marginLeft: "20px",
                  }}
                ></span>
              </div>
              {/* <div style={{ alignSelf: 'center' }}>
                                            </div> */}
            </div>
          </div>
          <div lg={12}>
            <p>
              <span className="text-blue welcome">Welcome </span>,{" "}
              {getGreeting(new Date().getHours())}
            </p>
          </div>
        </Card>
      </div>
      <div lg={12}>
        <div className="sidenav-section">
          <Link to="/org/dashboard" className="sidelink">
            <div className="sidenav-list">
              <div lg={3} className="side-icon">
                <span className="icon-dashboard_icon"></span>
              </div>
              <div lg={9} className="side-text">
                <h5>Dashboard</h5>
              </div>
            </div>
          </Link>
          <Link to="/org/dashboard/agents" className="sidelink">
            <div className="sidenav-list">
              <div lg={3} className="side-icon">
                <span className="icon-account"></span>
              </div>
              <div lg={9} className="side-text">
                <h5>Agents</h5>
              </div>
            </div>
          </Link>
          <Link to="/org/dashboard/dataentries/" className="sidelink">
            <div className="sidenav-list">
              <div lg={3} className="side-icon">
                <span className="icon-data_icon"></span>
              </div>
              <div lg={9} className="side-text">
                <h5>Data Entries</h5>
              </div>
            </div>
          </Link>
          <Link to="/org/dashboard/surveys" className="sidelink">
            <div className="sidenav-list active">
              <div lg={3} className="side-icon">
                <span className="icon-survey_icon"></span>
              </div>
              <div lg={9} className="side-text">
                <h5>Surveys</h5>
              </div>
            </div>
          </Link>
          <Link to="/org/dashboard/research" className="sidelink">
            <div className="sidenav-list active">
              <div lg={3} className="side-icon">
                <span className="icon-research_icon"></span>
              </div>
              <div lg={9} className="side-text">
                <h5>Research</h5>
              </div>
            </div>
          </Link>
          <Link to="/org/dashboard/interviews" className="sidelink">
            <div className="sidenav-list active">
              <div lg={3} className="side-icon">
                <span className="icon-interview_icon_ash"></span>
              </div>
              <div lg={9} className="side-text">
                <h5>Interviews</h5>
              </div>
            </div>
          </Link>
          <Link to="/org/dashboard/medicaloutreach" className="sidelink">
            <div className="sidenav-list active">
              <div lg={3} className="side-icon">
                <span className="icon-symptom_checker"></span>
              </div>
              <div lg={9} className="side-text">
                <h5>Medical Outreach</h5>
              </div>
            </div>
          </Link>
          <Link to="/org/dashboard/medicalsurvey" className="sidelink">
            <div className="sidenav-list active">
              <div lg={3} className="side-icon">
                <span className="icon-symptom_checker"></span>
              </div>
              <div lg={9} className="side-text">
                <h5>Medical Survey</h5>
              </div>
            </div>
          </Link>
          <Link to="/" className="sidelink" onClick={() => logout()}>
            <div className="sidenav-list active">
              <div lg={3} className="side-icon">
                <span className="icon-log_out"></span>
              </div>
              <div lg={9} className="side-text">
                <h5>logout</h5>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
