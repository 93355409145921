import React from "react";
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { navigate } from "@reach/router";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  dropdown: {
    width: "200px",
    display: "flex",
    height: "220px",
    flexDirection: "column",
    alignItems: "center",
  },
  fontSizeSm: {
    fontSize: ".9em",
    fontWeight: "300",
  },
  btn: {
    border: "1px solid gray",
    borderRadius: "10px",
    padding: ".2em .5em",
    marginTop: ".6em",
    cursor: "pointer",
  },
}));

export default function MenuListComposition({ img, name, email }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <div>
        <img
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          src={img}
          alt="profileHeader"
          style={{ borderRadius: "100%" }}
          className={"cursor-pointer profile"}
          height="40"
          width="40"
        />

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    className={classes.dropdown}
                  >
                    <MenuItem>
                      <img
                        src={img}
                        alt="ngo_profile_header"
                        style={{
                          borderRadius: "50%",
                          width: "70px",
                          height: "70px",
                          objectFit:"cover"
                        }}
                      />
                    </MenuItem>
                    <MenuItem onClick={handleClose}>{name}</MenuItem>
                    <MenuItem className={classes.fontSizeSm}>{email}</MenuItem>
                    <MenuItem
                      className={classes.btn}
                      onClick={() => navigate("/org/dashboard/settings")}
                    >
                      Manage Account
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
