import React from "react";

const Upload = React.forwardRef(({ callback, mime }, ref) => {
  const onFileSelected = (e) => {
    callback(e.target.files);
  };

  return (
    <>
      <input
        onChange={onFileSelected}
        accept={mime}
        ref={ref}
        style={{ display: "none" }}
        type="file"
      />
    </>
  );
});

export default Upload;
