import React, { Component, createContext } from "react";
import {
  deepComparison,
  getFromStorage,
  removeFromStorage,
  removeTokenFromStorage,
} from "../helpers";
import decoder from "jwt-decode";

export const containerContext = createContext(null);
const token = getFromStorage("authentication-token");

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: token ? decoder(token) : null,
      modalShow: false,
      survey: {},
      surveyID: null,
      data: [],
      prerequisites: [],
      disabled: true,
      visible: true,
      setSelectedProfileMenuItemIndex: 0,
      isLoading: false,
      searchTerm: "",
      searchContent: [],
    };
  }
  render() {
    return (
      <containerContext.Provider
        value={{
          state: this.state,
          toggle: () => {
            this.setState({
              modalShow: !this.state.modalShow,
            });
          },
          setSurveyID: (id) => {
            this.setState({ surveyID: id });
          },
          setSurvey: (name) => {
            this.setState({ survey: name });
          },
          setData: (data) => {
            this.setState({ data });
          },
          save: (API, id, token, notify, navigate, type) => {
            const { data, prerequisites } = this.state;
            if (type === "survey") {
              if (data.length > 0) {
                if (deepComparison(data, notify)) {
                  API.put(
                    `surveys/${id}`,
                    { prerequisites, questions: data },
                    {
                      headers: {
                        Authorization: `Bearer: ${token}`,
                      },
                    }
                  )
                    .then((res) => {
                      notify.toast.success(res.data.message);
                    })
                    .catch((err) => {
                      if (err.response) {
                        if (err.response.status === 401) {
                          removeFromStorage("authentication-token");
                          notify.toast.failure("Re-Authentication Required");
                          navigate("/login");
                        }

                        return notify.toast.failure(err.response.data.message);
                      } else {
                        return notify.toast.failure(err);
                      }
                    });
                }
              } else {
                return notify.toast.failure("Questions required");
              }
            } else if (type === "medicalsurvey") {
              if (data.length > 0) {
                if (deepComparison(data, notify)) {
                  API.put(
                    `medical/${id}`,
                    { prerequisites, questions: data },
                    {
                      headers: {
                        Authorization: `Bearer: ${token}`,
                      },
                    }
                  )
                    .then((res) => {
                      notify.toast.success(res.data.message);
                    })
                    .catch((err) => {
                      if (err.response) {
                        if (err.response.status === 401) {
                          removeFromStorage("authentication-token");
                          notify.toast.failure("Re-Authentication Required");
                          navigate("/login");
                        }

                        return notify.toast.failure(err.response.data.message);
                      } else {
                        // setLoading(false)
                        return notify.toast.failure(err);
                      }
                    });
                }
              } else {
                return notify.toast.failure("Questions required");
              }
            } else if (type === "medicaloutreach") {
              if (prerequisites.length > 0) {
                API.put(
                  `outreach/${id}`,
                  { prerequisites, questions: data },
                  {
                    headers: {
                      Authorization: `Bearer: ${token}`,
                    },
                  }
                )
                  .then((res) => {
                    notify.toast.success(res.data.message);
                  })
                  .catch((err) => {
                    if (err.response) {
                      if (err.response.status === 401) {
                        removeFromStorage("authentication-token");
                        notify.toast.failure("Re-Authentication Required");
                        navigate("/login");
                      }

                      return notify.toast.failure(err.response.data.message);
                    } else {
                      return notify.toast.failure(err.message);
                    }
                  });
              } else {
                return notify.toast.failure("Questions required");
              }
            }
          },
          publish: (API, id, token, notify, navigate, type, published) => {
            this.setState({
              isLoading: true,
            });

            let url = `surveys/${id}`;

            if (type === "medicalsurvey") url = `medical/${id}`;
            else if (type === "medicaloutreach") url = `outreach/${id}`;

            API.put(
              url,
              { published },
              {
                headers: {
                  Authorization: `Bearer: ${token}`,
                },
              }
            )
              .then((res) => {
                this.setState({
                  isLoading: false,
                });
                return published;
              })
              .catch((err) => {
                this.setState({
                  isLoading: false,
                });
                if (err.response) {
                  if (err.response.status === 401) {
                    removeTokenFromStorage();
                    notify.toast.failure("Re-Authentication Required");
                    navigate("/login");
                  }

                  return notify.toast.failure(err.response.data.message);
                } else {
                  return notify.toast.failure(err.message);
                }
              });
          },
          handleVisibleChange: (flag) => {
            this.setState({ visible: flag });
          },
          setDisabled: () => {
            this.setState({ disabled: false });
          },
          addPrerequisite: (p) => {
            this.setState({ prerequisites: p });
          },
          showModal: () => {
            this.setState({ modalShow: true });
          },
          setSelectedProfileMenuItemIndex: (index) => {
            this.setState({ selectedProfileMenuItemIndex: index });
          },
          setLoading: (value) => {
            this.setState({ isLoading: value });
          },
          setSearch: (value) => {
            this.setState({ searchTerm: value });
          },
        }}
      >
        {this.props.children}
      </containerContext.Provider>
    );
  }
}

export default Container;
