import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { Location } from "@reach/router";

// import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import Container from "./providers";
import { PubNubProvider } from "pubnub-react";
import PubNub from "pubnub";
import { LoadingScreen } from "./components/custom/";
import decoder from "jwt-decode";
import { getFromStorage } from "./helpers/";
import * as Redux from "react-redux";
import getStore from "./store";
import { FirebaseProvider } from "./providers/firebase";


const pubnub = new PubNub({
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
  uuid: getFromStorage("authentication-token")
    ? decoder(getFromStorage("authentication-token"))._id
    : null,
});

ReactDOM.render(
  // <BrowserRouter>
  <Redux.Provider store={getStore()}>
    <React.Suspense fallback={<LoadingScreen />}>
      <Container>
        <PubNubProvider client={pubnub}>
          <Location>
          <FirebaseProvider>
            <App />
          </FirebaseProvider>
          </Location>
        </PubNubProvider>
      </Container>
    </React.Suspense>
  </Redux.Provider>,
  // </BrowserRouter>
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
