import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import OverlayContext from "../providers/overlay";
import Spin from "antd/es/spin";
import { getFromStorage, removeFromStorage } from "../helpers";
import decoder from "jwt-decode";

export default (Component, message = null) => (props) => {
  const [initializing, setInitializing] = useState(true);
  useEffect(() => {
    const verify = () => {
      const token = getFromStorage("authentication-token");
      if (!token) return navigate("/login");
      const { accountType, exp } = decoder(token);
      let current_time = new Date().getTime() / 1000;
      if (current_time > exp) {
        navigate("/login");
        removeFromStorage("authentication-token");
        return;
      }
      if (!accountType) return navigate("/login");
      if (accountType === "admin") {
        setInitializing(false);
        return;
      } else {
        navigate("/login");
        return;
      }
    };
    verify();
    return () => {
      verify();
    };
  });

  return (
    <>
      {initializing ? (
        <div className="overlay">
          <div>
            <Spin size="large" />
            {message && <div>message</div>}
          </div>
        </div>
      ) : null}
      <OverlayContext.Provider value={setInitializing}>
        <Component {...props} />
      </OverlayContext.Provider>
    </>
  );
};
