// Import the functions you need from the SDKs you need
import React, { useState , useEffect} from 'react'
import firebase from 'firebase/app';
import 'firebase/firestore';
import { createContext } from "react";
import {
    getFromStorage 
} from "../helpers/";
import decoder from "jwt-decode";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export const db = app.firestore()


export const FirebaseContext = createContext(null);


// Create the Firebase provider component
export const FirebaseProvider = ({ children }) => {
  const token = getFromStorage("authentication-token");
  const [user, setUser] = useState();
  const [chats, setChats] = useState([])
  const sessionId = sessionStorage.getItem('SESSION');

  useEffect(() => {
    if(token) setUser(decoder(token));
        //eslint-disable-next-line
    }, [token, sessionId]);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [history, setHistory] = useState([])



  const fetchUserConversations = (userId) => {
    try {
        const userConversationsRef = db.collection('users').doc(userId).collection('conversations');

        const unsubscribe = userConversationsRef.onSnapshot(async (snapshot) => {
            if (snapshot.empty) {
                console.debug('No matching conversations.');
                return setHistory([]);
            }

            let conversations = [];

            for (const doc of snapshot.docs) {
                const conversationId = doc.id;
                let conversationData = { conversationId };

                const messagesSnapshot = await db.collection('users').doc(userId).collection('conversations').doc(conversationId).collection('messages').limit(1).get();

                if (!messagesSnapshot.empty) {
                    const firstMessageDoc = messagesSnapshot.docs[0];
                    conversationData.message = {
                        messageId: firstMessageDoc.id,
                        ...firstMessageDoc.data()
                    };
                }

                conversations.push(conversationData);
            }

            setHistory(conversations);
        });

        // Return the unsubscribe function to be able to stop listening when necessary
        return unsubscribe;

    } catch (error) {
        console.debug("Error fetching conversations: ", error);
        return setHistory([]);
    }
  }

    async function createSession() {
        try {
            const userMessageRef = db.collection('users').doc(user._id).collection('conversations');
             userMessageRef.add({}).then(res=>{
              sessionStorage.setItem('SESSION', res.id);
              setChats([])
            });
        } catch (e) {
            console.debug("Error creating session: ", e);
          }
    }

    async function addPrompt(userPrompt) {
      try {
        const userMessageRef = db.collection('users').doc(user._id).collection('conversations').doc(sessionId).collection('messages');
        let lastChat = chats[chats.length - 1]
        //if lastChat is empty add without parentMessageId otherwise use the last chat parentMessageId
        let docRef;
        if(lastChat){
             docRef = await userMessageRef.add({ prompt : userPrompt, parentMessageId: lastChat.parentMessageId });
        } else {
            docRef = await userMessageRef.add({ prompt : userPrompt })
        }
        // New message data
        const newMessageData = {
            messageId: docRef.id,
            prompt: userPrompt,
        };

        setHistory(prevHistory => {
            // Check if the conversationId (sessionId) already exists
            const existingIndex = prevHistory.findIndex(item => item.conversationId === sessionId);

            if (existingIndex !== -1) {
                // If it exists, update the message in that object
                prevHistory[existingIndex].message = newMessageData;
            } 
            // No need for an 'else' since we don't want to add new conversations

            return [...prevHistory];
        });
        console.debug("Document successfully written with ID:", docRef.id);
      } catch(e) {
        console.error("Error", e)
      }
    }

    function fetchMessages() {
      if (!user || !sessionId) return;

    const userMessageRef = db.collection('users').doc(user._id).collection('conversations').doc(sessionId).collection('messages');

    // Listen for changes using onSnapshot
    const unsubscribe = userMessageRef.onSnapshot(querySnapshot => {
        const messages = [];
        querySnapshot.forEach(doc => {
            messages.push({
                id: doc.id,
                ...doc.data()
            });
        });

        if (messages.length === 0) {
            console.debug('No messages found');
            setChats([])
        } else {
          let sorted = messages.sort((a, b) => {
                 const dateA = firestoreTimestampToDate(a?.status?.created_at?.seconds, a?.status?.created_at?.nanoseconds);
                 const dateB = firestoreTimestampToDate(b?.status?.created_at?.seconds, b?.status?.created_at?.nanoseconds);
                 return dateA - dateB;
             });
             setChats(sorted);
            // onNewMessages(messages);  // This can be a function to update your component's state.
        }

    }, err => {
        console.error("Error listening to changes:", err);
    });

    // Return the unsubscribe function so you can stop listening when necessary
    return unsubscribe;
  }
  function firestoreTimestampToDate(seconds, nanoseconds) {
        return new Date(seconds * 1000 + nanoseconds / 1000000);
  }
  
  async function deleteConversation(conversationId) {

    // Reference to the messages within the conversation
    const messagesRef = db.collection('users').doc(user._id).collection('conversations').doc(conversationId).collection('messages');
    
    // Fetch and delete all the messages
    return messagesRef.get()
        .then(querySnapshot => {
            // Batch all delete operations together for efficiency
            const batch = db.batch();

            querySnapshot.forEach(doc => {
                batch.delete(doc.ref);
            });

            // Execute all delete operations
            return batch.commit();
        })
        .then(() => {
            // Once all messages are deleted, delete the conversation
            return db.collection('users').doc(user._id).collection('conversations').doc(conversationId).delete();
            
          })
          .then(() => {
            // instead of removing i want to get the next history if there is none i would remove
            sessionStorage.removeItem('SESSION');
            console.debug("Successfully deleted the conversation and its messages.");
        })
        .catch((error) => {
            console.error("Error deleting the conversation and its messages: ", error);
        });
  }
  function handleConversationClick(conversationId) {
      // Set the sessionId to the clicked conversationId
      sessionStorage.setItem('SESSION', conversationId);
      // Update the state
      setChats([]);
      fetchMessages()
  }

  const firebase = {
      createSession,
      addPrompt,
      fetchMessages,
      setHistory,
      history,
      drawerOpen,
      setDrawerOpen,
      fetchUserConversations,
      user,
      chats,
      setChats,
      sessionId,
      deleteConversation,
      handleConversationClick
  };

  return (
      <FirebaseContext.Provider value={firebase}>
          {children}
      </FirebaseContext.Provider>
  );
};