import React, { useContext } from "react";
import ProfileFrame from "./ProfileFrame";
import { DrawerMenuController } from "../../../controllers";
import Guard from "../../../wrappers/authorization";
import { containerContext } from "../../../providers/";
import { LoadingScreen, BugReporter } from "../../custom";

export default Guard(({ children, ...props }) => {
  const context = useContext(containerContext);

  return (
    <>
      {context.state.isLoading ? (
        <LoadingScreen />
      ) : (
        <ProfileFrame
          // authState={authstate}
          drawerUserType={DrawerMenuController.USERTYPE.ORG}
          colorTheme={""}
          {...props}
        >
          {children}
          <BugReporter />
        </ProfileFrame>
      )}
    </>
  );
});
